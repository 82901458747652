import React, { useEffect, useState } from "react";
import "./style.scss";
import Loader from "../../../components/Loader";

import DetailBottom from "./DetailBottom";
import DetailTop from "./DetailTop";
import { getMovieDetailRequest } from "./module/action";
import { fetchScheduleCinemaSystem } from "../Home/cinemaBlock/module/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../../components/footer";
import NavbarHome from "../../../components/NavbarHome";

export default function Detail(props) {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  // const movieDetail = useSelector((state) => state.movieReducer.movieDetail);
  useEffect(() => {
    dispatch(fetchScheduleCinemaSystem(id));
  }, []);
  useEffect(() => {
    dispatch(getMovieDetailRequest(id, () => setLoading(false)));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <NavbarHome />
      <DetailTop />
      <DetailBottom />
      <Footer />
    </div>
  );
}
