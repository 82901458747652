import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
import {
  fetchCinemaChildInformation,
  fetchCinemaSystemInformation,
  fetchScheduleCinemaSystem,
} from "./module/action";
import format from "date-format";
import { addMinutes } from "../../../../helpers";
import Loader from "../../../../components/Loader";

function CinemaBlock(props) {
  const { cinemaSystemList, cinemaChildList, cinemaSchedule, dispatch } = props;
  //useState Collapsed film-wrap
  // const [isCollapse, setCollapse] = useState(false);
  const [isLoading, setLoading] = useState(true);
  // set color cinema child
  const listColorCinemaChild = [
    { maHeThongRap: "BHDStar", color: "#8bc541" },
    { maHeThongRap: "CGV", color: "red" },
    { maHeThongRap: "CineStar", color: "#df0d7a" },
    { maHeThongRap: "LotteCinima", color: "#ca4137" },
    { maHeThongRap: "MegaGS", color: "#e5a813" },
    { maHeThongRap: "Galaxy", color: "#ff9800" },
  ];
  const [colorCinemaChild, setColorCinemaChild] = useState("8bc541");

  // fetch data cinema system
  const [cinemaSystemSelected, setCinemaSystemSelected] = useState({
    maHeThongRap: "BHDStar",
  });
  useEffect(() => {
    dispatch(fetchCinemaSystemInformation());
  }, []);

  // fetch data cinema child
  const [cinemaChildSelected, setCinemaChildSelected] = useState({
    maCumRap: null,
  });
  useEffect(() => {
    dispatch(fetchCinemaChildInformation(cinemaSystemSelected.maHeThongRap));
    // color
    const index = listColorCinemaChild.findIndex((item) => {
      return item.maHeThongRap === cinemaSystemSelected.maHeThongRap;
    });
    setColorCinemaChild(listColorCinemaChild[index].color);
  }, [cinemaSystemSelected]);
  useEffect(() => {
    if (cinemaChildList) {
      setCinemaChildSelected({ maCumRap: cinemaChildList[0].maCumRap });
    }
  }, [cinemaChildList]);

  // fetch data cinema schedule
  useEffect(() => {
    dispatch(fetchScheduleCinemaSystem(cinemaSystemSelected.maHeThongRap));
  }, []);
  // Show Time
  const [showTime, setShowTime] = useState(null);
  useEffect(() => {
    // xét cinemaSchedule chưa load
    if (!cinemaSchedule) {
      if (cinemaChildList) {
        setCinemaChildSelected({ maCumRap: cinemaChildList[0].maCumRap });
      }
    } else {
      // choose cinema system
      const chooseCinemaSystem = cinemaSchedule.filter(
        (item) => item.maHeThongRap === cinemaSystemSelected.maHeThongRap
      );
      const listCinemaCluster = chooseCinemaSystem[0].lstCumRap;
      // select the selected cluster position in the array
      const index = listCinemaCluster.findIndex((item) => {
        return item.maCumRap === cinemaChildSelected.maCumRap;
      });
      if (index !== -1) {
        setShowTime(listCinemaCluster[index].danhSachPhim);
        // console.log(showTime);
      } else {
        setShowTime(null);
      }
    }
  }, [cinemaChildSelected]);

  // render cinema system
  const renderCinemaSystemList = () => {
    return cinemaSystemList?.map((item) => {
      return (
        <a
          key={item.maHeThongRap}
          onClick={() => {
            setCinemaSystemSelected({ maHeThongRap: item.maHeThongRap });
          }}
          className={`nav-item nav-link ${
            cinemaSystemSelected.maHeThongRap === item.maHeThongRap
              ? "active"
              : ""
          }`}
          data-toggle="tab"
          href={`#${item.maHeThongRap}`}
          aria-selected={`${
            cinemaSystemSelected.maHeThongRap === item.maHeThongRap
              ? "true"
              : "false"
          }`}
        >
          <img src={item.logo} alt={item.biDanh} />
        </a>
      );
    });
  };
  const renderCinemaSystemContent = () => {
    return cinemaSystemList?.map((item) => {
      return (
        <div
          key={item.maHeThongRap}
          className={`tab-pane fade ${
            item.maHeThongRap === cinemaSystemSelected.maHeThongRap
              ? "show active"
              : ""
          } `}
          id={`${item.maHeThongRap}`}
        >
          <div className="row">
            <div className="nav col-4 nav-location">
              {renderCinemaChildList()}
            </div>
            <div className="tab-content tab-content-location col-8">
              {renderCinemaChildListContent()}
            </div>
          </div>
        </div>
      );
    });
  };

  // render cinema child
  const renderNameTheater = (name, id) => {
    const index = name.search("-");
    if (id === 0) {
      return name.slice(0, index - 1);
    }
    return name.slice(index + 1, name.length);
  };

  const renderCinemaChildList = () => {
    return cinemaChildList?.map((item) => {
      return (
        <a
          key={item.maCumRap}
          className={`nav-item nav-link ${
            item.maCumRap === cinemaChildSelected.maCumRap ? "active" : ""
          }`}
          data-toggle="tab"
          href={`#${item.maCumRap}`}
          aria-selected={`${
            cinemaChildSelected.maCumRap === item.maCumRap ? "True" : "false"
          }`}
          onClick={() => {
            setCinemaChildSelected({ maCumRap: item.maCumRap });
          }}
        >
          <div className="location">
            <img
              src="https://s3img.vcdn.vn/123phim/2021/01/bhd-star-bitexco-16105952137769.png"
              alt
            />
            <div className="info">
              <h4 className="cinema-name">
                <span style={{ color: colorCinemaChild }}>
                  {renderNameTheater(item.tenCumRap, 0)}
                </span>
                {" -"} {renderNameTheater(item.tenCumRap)}
              </h4>
              <p className="address">{item.diaChi}</p>
              {/* <div className="detail">[chi tiết]</div> */}
            </div>
          </div>
        </a>
      );
    });
  };
  const renderCinemaChildListContent = () => {
    return cinemaChildList?.map((item) => {
      return (
        <div
          key={item.maCumRap}
          className={`tab-pane list-movie fade ${
            item.maCumRap === cinemaChildSelected.maCumRap ? "show active" : ""
          } `}
          id={item.maCumRap}
        >
          {renderMovieShowTime()}
        </div>
      );
    });
  };

  // render movie showTime
  const renderMovieShowTime = () => {
    // console.log("123");
    if (showTime) {
      let renderContent = showTime.map((item, index) => {
        // lay ngay dau tien trong danh sach phim
        let date = item.lstLichChieuTheoPhim[0].ngayChieuGioChieu;
        let dateFormat = format("yyyy-mm-dd", new Date(date));
        //loc toan bo ngay dau tien
        let timeList = [];

        item.lstLichChieuTheoPhim.forEach((lichChieu) => {
          const formatNgayChieu = format(
            "yyyy-mm-dd",
            new Date(lichChieu.ngayChieuGioChieu)
          );
          const giochieu = addMinutes(
            new Date(lichChieu.ngayChieuGioChieu),
            120
          ).toString();
          // neu ngay chieu trung v ngay dau tien thi push vao mang moi
          if (formatNgayChieu === dateFormat) {
            const infoSchedule = {
              maLichChieu: lichChieu.maLichChieu,
              ngayChieuGioChieu: format(
                "hh:mm",
                new Date(lichChieu.ngayChieuGioChieu)
              ),
              gioChieu: format("hh:mm", new Date(giochieu)),
            };
            timeList.push(infoSchedule);
          }
        });
        // console.log(timeList);

        return (
          <div className="film-item" key={index}>
            <div
              className="film-wrap" //collapsed
              data-toggle="collapse"
              href={`#${item.maPhim}`}
              aria-expanded="true" //false chỉ cần bỏ show và thêm show
              aria-controls={item.maPhim}
              // onClick={() => {
              //   setCollapse(!isCollapse);
              // }}
            >
              <img src={item.hinhAnh} alt={item.maPhim} />
              <div className="info">
                <h4 className="cinema-name">
                  <span className="ageType">C18</span>
                  {item.tenPhim}
                </h4>
                <p className="address">111 phút - TIX 0 - IMDb 0</p>
              </div>
            </div>
            <div className="type-time collapse show" id={item.maPhim}>
              <p className="type">2D Digital</p>
              {timeList?.map((time) => {
                return (
                  <Link
                    to={`/booking/${time.maLichChieu}`}
                    key={time.maLichChieu}
                    className="time"
                  >
                    <span>{time.ngayChieuGioChieu}</span> ~ {time.gioChieu}
                  </Link>
                );
              })}
            </div>
          </div>
        );
      });
      return renderContent;
    } else {
      return (
        <div className="text-center pt-5" style={{ fontSize: "20px" }}>
          Không có suất chiếu nào
        </div>
      );
    }
  };
  // if (isLoading) return <Loader />;
  return (
    <section className="cinemaBlock" id="cumRap">
      <div className="container">
        <div className="row">
          <div className="nav flex-column col-md-1 col-2 nav-logo">
            {renderCinemaSystemList()}
          </div>
          <div className="tab-content tab-content-logo col-md-11 col-10">
            {renderCinemaSystemContent()}
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    cinemaSystemList: state.cinemaReducer.cinemaSystemList,
    cinemaChildList: state.cinemaReducer.cinemaChildList,
    cinemaSchedule: state.cinemaReducer.cinemaSchedule,
  };
};
export default connect(mapStateToProps)(CinemaBlock);
