import * as yup from "yup";
export const addMinutes = (dt, min) => {
  return new Date(dt.getTime() + min * 60000);
};
export const youTubeIdFromLink = (url) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/
  )[1];

export const createAction = (type, payload) => {
  return {
    type,
    payload,
  };
};
export const renderNameTheater = (name, color) => {
  const arr = splitStringByHyphen(name);
  return arr.map((item, index) => {
    return index === 0 ? (
      <span
        key={index}
        className="title__theater "
        style={{ color: color, fontWeight: 500 }}
      >
        {item}
      </span>
    ) : (
      <span key={index}>-{item}</span>
    );
  });
};
export const splitStringByHyphen = (string) => {
  const arr = string.replace(/'/gi, "").split("-");
  return arr;
};

// ===========YUP validate form =============
export const resgisterSchema = yup.object().shape({
  taiKhoan: yup.string().required("* Không được để trống!"),
  matKhau: yup.string().required("* Không được để trống!"),
  hoTen: yup.string().required("* Không được để trống!"),
  email: yup
    .string()
    .required("* Không được để trống!")
    .email("* Email không đúng định dạng!"),
  soDt: yup
    .string()
    .required("* Không được để trống!")
    .matches(/^[0-9]+$/, "* vui lòng nhập định dạng số!"),
});

export const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("* Bạn chưa nhập mật khẩu cũ"),
  newPassword: yup.string().required("* Bạn chưa nhập mật khẩu mới"),
  newPasswordConfirm: yup.string().required("* Bạn chưa nhập lại mật khẩu mới"),
});

export const postFilmSchema = yup.object().shape({
  maPhim: yup.number().required("* Mã phim không được để trống"),
  tenPhim: yup.string().required("* Tên phim không được để trống"),
  biDanh: yup.string().required("* Bí danh không được để trống"),
  trailer: yup.string().required("* trailer không được để trống"),
  moTa: yup.string(),
  ngayKhoiChieu: yup
    .string()
    .matches(
      "^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$",
      "Vui lòng nhập đúng định dạng dd/mm/yyyy"
    )
    .required("* Ngày khởi chiếu không được để trống"),

  danhGia: yup
    .number()
    .required("* Đánh già không được để trống")
    .min(0, "* Thấp nhất là 0")
    .max(10, "* cao nhất là 10"),
  hinhAnh: yup.string().required("* Hình ảnh không được để trống"),
});
export const editFilmSchema = yup.object().shape({
  maPhim: yup.number().required("* Mã phim không được để trống"),
  tenPhim: yup.string().required("* Tên phim không được để trống"),
  biDanh: yup.string().required("* Bí danh không được để trống"),
  trailer: yup.string().required("* trailer không được để trống"),
  moTa: yup.string(),
  ngayKhoiChieu: yup.string().required("* Ngày khởi chiếu không được để trống"),

  danhGia: yup
    .number()
    .required("* Đánh già không được để trống")
    .min(0, "* Thấp nhất là 0")
    .max(10, "* cao nhất là 10"),
  hinhAnh: yup.string().required("* Hình ảnh không được để trống"),
});
export const addUserSchema = yup.object().shape({
  taiKhoan: yup.string().required("* Tài khoản không được để trống"),
  matKhau: yup.string().required("* Mật khẩu không được để trống"),
  email: yup
    .string()
    .required("* Email không được để trống")
    .email("* Vui lòng nhập đúng định dạng email"),
  hoTen: yup.string().required("* Họ tên không được để trống"),
  soDt: yup.string().required("* Số điện thoại không được để trống"),
});
