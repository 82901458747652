import { userService } from "../../../services";
import { createAction } from "../../../helpers";
import * as constants from "./constants";
import Swal from "sweetalert2";

export const fetchUserInformationAPI = (data, callback) => {
  return (dispatch) => {
    userService
      .fetchUserInformationAPI(data)
      .then((res) => {
        dispatch(
          createAction(constants.FETCH_USER_INFORMATION_SUCCESS, res.data)
        );
        callback();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Lỗi hệ thống!",
          text: `${err.response.data}`,
        });
      });
  };
};

export const actUpdateInformationAPI = (data) => {
  return () => {
    const user = JSON.parse(localStorage.getItem("User"));
    userService
      .actUpdateInformationAPI(data, user)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Cập nhật thành công!",
          text: `${res.statusText}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Cập nhật thất bại!",
          text: `${err.response.data}`,
        });
      });
  };
};
