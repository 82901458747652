import React from "react";
import "./style.scss";

export default function Loader() {
  return (
    <div id="preloader">
      <div id="loader"></div>
    </div>
  );
}
