import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resgisterSchema } from "../../../helpers";
import {
  actUpdateInformationAPI,
  fetchUserInformationAPI,
} from "../module/action";
import Loader from "../../../components/Loader";

export default function UserInformation() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("User"));
    dispatch(fetchUserInformationAPI(user.taiKhoan, () => setLoading(false)));
  }, []);

  const { maLoaiNguoiDung } = useSelector(
    (state) => state.userLoginReducer.userLogin
  );
  const user = useSelector((state) => state?.userReducer?.userInfo);
  const alertError = (err) => {
    return (
      <div
        style={{ fontWeight: 500, textAlign: "left" }}
        className="text-danger"
      >
        {err}
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      hoTen: user.hoTen,
      taiKhoan: user.taiKhoan,
      soDt: user.soDT,
      email: user.email,
      maNhom: user.maNhom,
      maLoaiNguoiDung: maLoaiNguoiDung,
      matKhau: user.matKhau,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(actUpdateInformationAPI(values));
    },

    validationSchema: resgisterSchema,
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="wrapper container">
      <div className="header">
        <h2 className="title">Cập nhật thông tin cá nhân</h2>
      </div>
      <form className="content" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="">Họ tên</label>
          <input
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.hoTen}
            name="hoTen"
          />
          {formik.errors.hoTen ? alertError(formik.errors.hoTen) : null}
        </div>
        <div className="form-group">
          <label htmlFor="">Số điện thoại</label>
          <input
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.soDt}
            name="soDt"
          />
          {formik.errors.soDt ? alertError(formik.errors.soDt) : null}
        </div>
        <div className="form-group">
          <label htmlFor="">Email</label>
          <input
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
          />
          {formik.errors.email ? alertError(formik.errors.email) : null}
        </div>
        <button type="submit" className="btn btn-update">
          Cập nhật tài khoản
        </button>
      </form>
    </div>
  );
}
