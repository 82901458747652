import { userService } from "../../../../services";
import { createAction } from "../../../../helpers/index";
import * as constants from "./constants";
import swal from "sweetalert2";
export const actLoginAPI = (data, history) => {
  return (dispatch) => {
    userService
      .actLoginAPI(data)
      .then((res) => {
        if (res.data.maLoaiNguoiDung) {
          dispatch(createAction(constants.USER_LOGIN_SUCCESS, res.data));
          localStorage.setItem("User", JSON.stringify(res.data));
          history.replace("/");
        } else {
          return Promise.reject({
            response: { data: "Bạn chưa đăng ký!" },
          });
        }
      })
      .catch((err) => {
        swal.fire({
          icon: "error",
          title: "Đăng nhập thất bại",
          text: `${err.response.data}`,
        });
        dispatch(createAction(constants.USER_LOGIN_FAILED, err));
      });
  };
};

export const actTryLogin = () => {
  return (dispatch) => {
    if (!localStorage.getItem("User")) {
      return;
    }
    const user = JSON.parse(localStorage.getItem("User"));
    dispatch(createAction(constants.USER_LOGIN_SUCCESS, user));
  };
};
