import Home from "../containers/HomeTemplate/Home";
import Detail from "../containers/HomeTemplate/Detail";
import Login from "../containers/HomeTemplate/Login";
import Register from "../containers/HomeTemplate/Register";

import MovieManagement from "../containers/AdminTemplate/MovieManagement";
import UserManagement from "../containers/AdminTemplate/UserManagement";
import AddMovie from "../containers/AdminTemplate/MovieManagement/AddMovie";
import EditMovie from "../containers/AdminTemplate/MovieManagement/EditMovie";
import AddUser from "../containers/AdminTemplate/UserManagement/AddUser";
import EditUser from "../containers/AdminTemplate/UserManagement/EditUser";
import Booking from "../containers/HomeTemplate/Booking";
import UserInformation from "../containers/UserTemplate/UserInformation";
import ChangePasswordUser from "../containers/UserTemplate/ChangePasswordUser";
import HistoryBookingTicket from "../containers/UserTemplate/HistoryBookingTicket";

export const routeHome = [
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    exact: false,
    path: "/detail/:id",
    component: Detail,
  },
  // {
  //   exact: false,
  //   path: "/booking/:id",
  //   component: Booking,
  // },
  {
    exact: false,
    path: "/login",
    component: Login,
  },
  {
    exact: false,
    path: "/register",
    component: Register,
  },
];

export const routeUser = [
  {
    exact: false,
    path: "/user/thongtincanhan",
    component: UserInformation,
  },
  {
    exact: false,
    path: "/user/thaydoimatkhau",
    component: ChangePasswordUser,
  },
  {
    exact: false,
    path: "/user/lichsudatve",
    component: HistoryBookingTicket,
  },
];

export const routeAdmin = [
  {
    exact: true,
    path: "/admin",
    component: MovieManagement,
  },
  {
    exact: false,
    path: "/admin/movie",
    component: MovieManagement,
  },
  {
    exact: false,
    path: "/admin/addmovie",
    component: AddMovie,
  },
  {
    exact: false,
    path: "/admin/editmovie/:id",
    component: EditMovie,
  },
  {
    exact: false,
    path: "/admin/user",
    component: UserManagement,
  },
  {
    exact: false,
    path: "/admin/adduser",
    component: AddUser,
  },
  {
    exact: false,
    path: "/admin/edituser/:id",
    component: EditUser,
  },
];
