import React, { useEffect, useState } from "react";
import "./style.scss";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { actDeleteFilmAPI, fetchListFilmManagement } from "../module/action";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import ModalVideo from "react-modal-video";
import { youTubeIdFromLink } from "../../../../helpers";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../../../components/Loader";

const columns = [
  { id: "maPhim", label: "Mã phim" },
  { id: "tenPhim", label: "Tên phim" },
  { id: "hinhAnh", label: "Hình ảnh" },
  { id: "trailer", label: "Trailer" },
  { id: "ngayKhoiChieu", label: "Ngày khởi chiếu" },
  { id: "congCu", label: "Công cụ" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 500,
  },
});

export default function MovieList() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isOpen, setOpen] = useState(false);
  const [isURL, setURL] = useState("");
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListFilmManagement(() => setLoading(false)));
  }, []);
  const filmList = useSelector((state) => state?.filmManagement?.filmList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderMovie = () => {
    return filmList
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        const { maPhim, tenPhim, hinhAnh, trailer, ngayKhoiChieu } = item;
        return (
          <TableRow key={maPhim}>
            <TableCell>{maPhim}</TableCell>
            <TableCell>{tenPhim}</TableCell>
            <TableCell>
              {<img src={hinhAnh} style={{ width: 100 }} />}
            </TableCell>
            <TableCell>
              {
                <button
                  className="btn "
                  onClick={() => {
                    setOpen(true);
                    setURL(youTubeIdFromLink(trailer));
                  }}
                >
                  <i class="fas fa-play hover-icon"></i>
                </button>
              }
            </TableCell>
            <TableCell>{dateFormat(ngayKhoiChieu, "dd/mm/yyyy")}</TableCell>
            <TableCell>
              {
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Tooltip
                      title="Tạo Lịch Chiếu"
                      style={{ color: "#007bff" }}
                    >
                      <Link to={`/admin/create-schdule/${maPhim}`}>
                        <i class="fas fa-video hover-icon" />
                      </Link>
                    </Tooltip> */}
                    <Tooltip title="Sửa Phim" style={{ color: "#007b3e" }}>
                      <Link to={`/admin/editMovie/${maPhim}`}>
                        <i className="fa fa-edit hover-icon" />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Xóa Phim">
                      <div>
                        <i
                          style={{ cursor: "pointer", color: "#fb4226" }}
                          className="fa fa-trash-alt hover-icon"
                          onClick={() => {
                            Swal.fire({
                              icon: "question",
                              title: "Bạn chắc chứ?",
                              text: `Xóa film ${tenPhim}`,
                              showCancelButton: true,
                            }).then((res) => {
                              if (res.isConfirmed) {
                                dispatch(actDeleteFilmAPI(maPhim));
                              }
                            });
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              }
            </TableCell>
          </TableRow>
        );
      });
  };

  if (isLoading) {
    <Loader />;
  }
  return (
    <Paper className="classesRoot">
      <TableContainer className="classesContainer">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderMovie()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={filmList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={isURL}
        onClose={() => setOpen(false)}
      />
    </Paper>
  );
}
