import AuthService from "./AuthService";
import BookingService from "./Booking";
import CinemaService from "./cinemaService";
import FilmManagementService from "./FilmManagementService";
import FilmService from "./filmServices";
import MovieService from "./movieService";
import UserManagementServices from "./UserManagementServices";
import UserService from "./UserService";

export const cinemaService = new CinemaService();
export const filmService = new FilmService();
export const movieService = new MovieService();
export const bookingService = new BookingService();
export const userService = new UserService();
export const filmManagementService = new FilmManagementService();
export const usermanagementService = new UserManagementServices();
export const authService = new AuthService();
