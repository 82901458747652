import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import format from "date-format";
import Loader from "../../../components/Loader";
import { fetchUserInformationAPI } from "../module/action";

export default function HistoryBookingTicket() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("User"));
    dispatch(fetchUserInformationAPI(user.taiKhoan, () => setLoading(false)));
  }, []);

  const listTicketBooked = useSelector(
    (state) => state?.userReducer?.userInfo?.thongTinDatVe
  );

  const renderListHistoryTicket = () => {
    return listTicketBooked?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.maVe}</td>
          <td>{format("dd/MM/yyyy", new Date(item.ngayDat))}</td>
          <td>{item.tenPhim}</td>
          <td>{item.giaVe}</td>
          <td>{item.thoiLuongPhim}</td>
        </tr>
      );
    });
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="booking-history container">
      <h2 className="title">Lịch sử đặt vé</h2>
      <div className="content">
        <div className="table">
          <Table bordered>
            <thead className="thead-dark">
              <tr>
                <th>Mã vé</th>
                <th>Ngày đặt</th>
                <th>Tên phim</th>
                <th>Giá vé</th>
                <th>Thời lượng phim</th>
              </tr>
            </thead>
            <tbody>{renderListHistoryTicket()}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
