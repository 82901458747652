import axios from "axios";

class UserManagementServices {
  fetchListUserManagementAPI = () => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/LayDanhSachNguoiDung?MaNhom=GP10",
      method: "GET",
    });
  };
  deleteUserManagementAPI = (data, token) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/XoaNguoiDung?TaiKhoan=${data}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  addUserAPI = (data, token) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/ThemNguoiDung",
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  fetchUserInfoManagementAPI = (data) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/ThongTinTaiKhoan",
      method: "POST",
      data,
    });
  };
  editUserInfoManagementAPI = (data, token) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/CapNhatThongTinNguoiDung",
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  findUserAPI = (data) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/TimKiemNguoiDung?MaNhom=GP10&tuKhoa=${data}`,
      method: "GET",
    });
  };
}
export default UserManagementServices;
