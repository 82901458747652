export const FETCH_TICKET_ROOM_REQUEST = "Booking/FETCH_TICKET_ROOM_REQUEST";
export const FETCH_TICKET_ROOM_SUCCESS = "Booking/FETCH_TICKET_ROOM_SUCCESS";
export const FETCH_TICKET_ROOM_FAIL = "Booking/FETCH_TICKET_ROOM_FAIL";

export const PUSH_SEAT = "BookingTicket/PUSH_SEAT";

export const CHANGE_COMBO_NUMBER = "BookingTicket/CHANGE_COMBO_NUMBER";

export const BOOK_TICKET_REQUEST = "BookingTicket/BOOK_TICKET_REQUEST";
export const BOOK_TICKET_SUCCESS = "BookingTicket/BOOK_TICKET_SUCCESS";
export const BOOK_TICKET_FAIL = "BookingTicket/BOOK_TICKET_FAIL";
