import { createAction } from "../../../../helpers";
import { userService } from "../../../../services";
import * as constants from "./constants";
import Swal from "sweetalert2";

export const actRegisterAPI = (data, history) => {
  return (dispatch) => {
    userService
      .actRegisterAPI(data)
      .then((res) => {
        Swal.fire({ icon: "success", title: "Đăng ký thành công" });
        dispatch(createAction(constants.USER_REGISTER_SUCCESS, res.data));
        history.replace("/login");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Đăng ký thất bại!",
          text: `${err.response.data}`,
        });
      });
  };
};
