import axios from "axios";

class FilmService {
  fetchFilmShowing = () => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhim?maNhom=GP10",
      method: "GET",
    });
  };
  fetchFilmUpcoming = async () => {
    return await axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhim?maNhom=GP07",
      method: "get",
    });
  };
}
export default FilmService;
