import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { postFilmSchema } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { actAddFilmAPI, fetchListFilmManagement } from "../module/action";
import Loader from "../../../../components/Loader";

export default function AddMovie() {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchListFilmManagement(() => setLoading(false)));
  }, []);
  const filmList = useSelector((state) => state?.filmManagement?.filmList);
  const handleAlertError = (err) => {
    return <div className="text-danger">{err}</div>;
  };
  const randomFilmCode = () => {
    let rand = null;
    do {
      let flag = false;
      rand = Math.floor(Math.random() * 9999) + 1000;
      filmList?.map((item) => {
        if (item.maPhim === rand) {
          flag = true;
        }
      });
      if (!flag) {
        return rand;
      }
    } while (true);
  };

  const formik = useFormik({
    initialValues: {
      maPhim: randomFilmCode(),
      tenPhim: "",
      biDanh: "",
      trailer: "",
      hinhAnh: "",
      moTa: "",
      maNhom: "GP10",
      ngayKhoiChieu: "",
      danhGia: 0,
    },
    validationSchema: postFilmSchema,
    onSubmit: (values) => {
      dispatch(actAddFilmAPI(values));
      console.log(values);
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="container addMovie">
      <h2 className="title">ADD MOVIE</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Tên phim</label>
              <input
                type="text"
                className="form-control"
                name="tenPhim"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.tenPhim &&
                formik.touched.tenPhim &&
                handleAlertError(formik.errors.tenPhim)}
            </div>
            <div className="form-group">
              <label>Trailer</label>
              <input
                type="text"
                className="form-control"
                name="trailer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.trailer &&
                formik.touched.trailer &&
                handleAlertError(formik.errors.trailer)}
            </div>
            <div className="form-group">
              <label>Bí danh</label>
              <input
                type="text"
                className="form-control"
                name="biDanh"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.biDanh &&
                formik.touched.biDanh &&
                handleAlertError(formik.errors.biDanh)}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Ngày khởi chiếu</label>
              <input
                type="text"
                className="form-control"
                name="ngayKhoiChieu"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.ngayKhoiChieu &&
                formik.touched.ngayKhoiChieu &&
                handleAlertError(formik.errors.ngayKhoiChieu)}
            </div>
            <div className="form-group">
              <label>Đánh giá</label>
              <input
                type="number"
                className="form-control"
                name="danhGia"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.danhGia &&
                formik.touched.danhGia &&
                handleAlertError(formik.errors.danhGia)}
            </div>
            <div className="form-group">
              <label>Hình ảnh</label>
              <input
                type="text"
                className="form-control"
                name="hinhAnh"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.hinhAnh &&
                formik.touched.hinhAnh &&
                handleAlertError(formik.errors.hinhAnh)}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Mô tả</label>
              <textarea
                type="text"
                className="form-control"
                name="moTa"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
}
