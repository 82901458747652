import React, { useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { addUserSchema } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { addUserAPI } from "../module/action";
import Loader from "../../../../components/Loader";
import { useHistory } from "react-router";

export default function AddUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleAlertError = (err) => {
    return <div className="text-danger">{err}</div>;
  };

  const formik = useFormik({
    initialValues: {
      taiKhoan: "",
      matKhau: "",
      email: "",
      hoTen: "",
      soDt: "",
      maLoaiNguoiDung: "KhachHang",
      maNhom: "GP10",
    },
    validationSchema: addUserSchema,
    onSubmit: (values) => {
      dispatch(addUserAPI(values, history));
    },
  });
  return (
    <div className="container addMovie">
      <h2 className="title">ADD USER</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Tài khoản</label>
              <input
                type="text"
                className="form-control"
                name="taiKhoan"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.taiKhoan &&
                formik.touched.taiKhoan &&
                handleAlertError(formik.errors.taiKhoan)}
            </div>
            <div className="form-group">
              <label>Mật khẩu</label>
              <input
                type="text"
                className="form-control"
                name="matKhau"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.matKhau &&
                formik.touched.matKhau &&
                handleAlertError(formik.errors.matKhau)}
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email &&
                formik.touched.email &&
                handleAlertError(formik.errors.email)}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Họ tên</label>
              <input
                type="text"
                className="form-control"
                name="hoTen"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.hoTen &&
                formik.touched.hoTen &&
                handleAlertError(formik.errors.hoTen)}
            </div>
            <div className="form-group">
              <label>Số điện thoại</label>
              <input
                type="text"
                className="form-control"
                name="soDt"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.soDt &&
                formik.touched.soDt &&
                handleAlertError(formik.errors.soDt)}
            </div>
            <div className="form-group">
              <label>Loại người dùng</label>
              <select
                className="form-control"
                name="maLoaiNguoiDung"
                onChange={formik.handleChange}
              >
                <option value="KhachHang">Khách hàng</option>
                <option value="QuanTri">Quản trị</option>
              </select>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
}
