import Swal from "sweetalert2";
import { createAction } from "../../../../helpers";
import { usermanagementService } from "../../../../services";
import * as constants from "./constants";
export const fetchListUserManagementAPI = (callback) => {
  return (dispatch) => {
    usermanagementService.fetchListUserManagementAPI().then((res) => {
      dispatch(
        createAction(constants.FETCH_LIST_USER_MANAGEMENT_API_SUCCESS, res.data)
      );
      callback();
    });
  };
};
export const deleteUserManagementAPI = (data) => {
  const token = JSON.parse(localStorage.getItem("User")).accessToken;
  return (dispatch) => {
    usermanagementService
      .deleteUserManagementAPI(data, token)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Xóa thành công",
        }).then(() => {
          usermanagementService.fetchListUserManagementAPI().then((res) => {
            dispatch(
              createAction(
                constants.FETCH_LIST_USER_MANAGEMENT_API_SUCCESS,
                res.data
              )
            );
          });
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Xóa thất bại",
          text: `${err.response.data}`,
        });
      });
  };
};
export const addUserAPI = (data, history) => {
  const token = JSON.parse(localStorage.getItem("User")).accessToken;
  return () => {
    usermanagementService
      .addUserAPI(data, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Thêm thành công",
        }).then(() => {
          history.push("/admin/user");
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Thêm thất bại",
          text: `${err.response.data}`,
        });
      });
  };
};

export const fetchUserInfoManagementAPI = (data, loading) => {
  const userAccount = {
    taiKhoan: data,
  };
  return (dispatch) => {
    usermanagementService
      .fetchUserInfoManagementAPI(userAccount)
      .then((res) => {
        dispatch(
          createAction(
            constants.FETCH_USER_INFO_MANAGEMENT_API_SUCCESS,
            res.data
          )
        );
        loading();
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const editUserInfoManagementAPI = (data, history) => {
  const token = JSON.parse(localStorage.getItem("User")).accessToken;
  return () => {
    usermanagementService
      .editUserInfoManagementAPI(data, token)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sửa thành công",
        }).then(() => {
          history.push("/admin/user");
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sửa thất bại",
          text: `${err.response.data}`,
        });
      });
  };
};

export const findUserAPI = (data) => {
  if (data) {
    return (dispatch) => {
      usermanagementService
        .findUserAPI(data)
        .then((res) => {
          dispatch(
            createAction(
              constants.FETCH_LIST_USER_MANAGEMENT_API_SUCCESS,
              res.data
            )
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data}`,
          });
        });
    };
  } else {
    return (dispatch) => {
      usermanagementService
        .fetchListUserManagementAPI()
        .then((res) => {
          dispatch(
            createAction(
              constants.FETCH_LIST_USER_MANAGEMENT_API_SUCCESS,
              res.data
            )
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data}`,
          });
        });
    };
  }
};
