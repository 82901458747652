import React from "react";
import { Redirect, Route } from "react-router";
import NavbarHome from "../../components/NavbarHome";
import Footer from "../../components/footer";
import UserSidebar from "./UserSidebar";
import "./style.scss";

function LayoutUser(props) {
  return (
    <>
      <NavbarHome />
      <div style={{ paddingTop: 55 }} className="container-fluid userInfo">
        <div className="row">
          <div className="col-4 col-lg-2 customPadding">
            <UserSidebar />
          </div>
          <div className="col-8 col-lg-10 customPadding">{props.children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default function UserTemplate(props) {
  const { exact, path, component } = props;
  if (!localStorage.getItem("User")) return <Redirect to="/login" />;
  return (
    <div>
      <LayoutUser>
        <Route exact={exact} path={path} component={component} />
      </LayoutUser>
    </div>
  );
}
