import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeTemplate from "./containers/HomeTemplate";
import { routeAdmin, routeHome, routeUser } from "./routes";
import { useEffect } from "react";
import { actTryLogin } from "./containers/HomeTemplate/Login/module/actions";
import { useDispatch } from "react-redux";
import AdminTemplate from "./containers/AdminTemplate";
import Auth from "./containers/AdminTemplate/Auth";
import Home from "./containers/HomeTemplate/Home";
import Booking from "./containers/HomeTemplate/Booking";
import ProtectedRoute from "./components/ProtectedRoute";
import UserInformation from "./containers/UserTemplate/UserInformation";
import ChangePasswordUser from "./containers/UserTemplate/ChangePasswordUser";
import HistoryBookingTicket from "./containers/UserTemplate/HistoryBookingTicket";
import UserTemplate from "./containers/UserTemplate";

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actTryLogin());
  }, []);
  const showLayoutHome = (route) => {
    if (route && route.length > 0) {
      return route.map((item, index) => {
        return (
          <HomeTemplate
            key={index}
            exact={item.exact}
            path={item.path}
            component={item.component}
          />
        );
      });
    }
  };

  const showLayoutAdmin = (route) => {
    if (route && route.length > 0) {
      return route.map((item, index) => {
        return (
          <AdminTemplate
            key={index}
            exact={item.exact}
            path={item.path}
            component={item.component}
          />
        );
      });
    }
  };
  const showLayoutUser = (route) => {
    if (route && route.length > 0) {
      return route.map((item, index) => {
        return (
          <UserTemplate
            key={index}
            exact={item.exact}
            path={item.path}
            component={item.component}
          />
        );
      });
    }
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {showLayoutHome(routeHome)}
          {showLayoutAdmin(routeAdmin)}
          {showLayoutUser(routeUser)}
          <ProtectedRoute
            exact={false}
            path="/booking/:id"
            component={Booking}
          />
          <Route path="/auth" component={Auth} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
