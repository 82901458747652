import React, { useState } from "react";
import "./style.scss";
import ModalVideo from "react-modal-video";
import { youTubeIdFromLink } from "../../../../helpers";
import dateFormat from "dateformat";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

export default function DetailTop(props) {
  const [isOpen, setOpen] = useState(false);
  const movieDetail = useSelector((state) => state.movieReducer.movieDetail);
  return (
    <div>
      <div className="detail__top">
        <ModalVideo
          mute={1}
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId={youTubeIdFromLink(`${movieDetail?.trailer}`)}
          onClose={() => setOpen(false)}
        />
        <img className="bg" src={movieDetail?.hinhAnh} alt="" />

        <div className="gradient"></div>

        <div className="detail__content">
          <div className="content__left col-9">
            <div className="thumbnail">
              <img
                style={{ width: "100%", maxWidth: 250 }}
                // className="w-100"
                src={movieDetail?.hinhAnh}
                alt=""
              />
              <div className="overlay"></div>

              {/* modal video */}

              <div onClick={() => setOpen(true)} className="btn button-player">
                XEM VIDEO
              </div>
            </div>
            <div className="info">
              <p className="date">
                {dateFormat(movieDetail?.ngayKhoiChieu, "dd-mm-yyyy")}
              </p>
              <div className="title">
                <button className="btn">C13</button>
                {movieDetail?.tenPhim}
              </div>
              <p className="movie-length">100 phút - 0 IMDb - 2D/Digital</p>
              <a href="#showTimeDetail">
                <button className="btn button-purchase">Mua vé</button>
              </a>
            </div>
          </div>
          <div className="content__right col-3">
            <div className="circle">{movieDetail?.danhGia}</div>
            <span className="evaluate">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <span>&#189;</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
