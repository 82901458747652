import React from "react";
import "./style.scss";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/img/logo/booking-movie-logo.png";
import avtLogin from "../../assets/img/icon/avatar.png";
import boy from "../../assets/img/boy.png";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { actLogout } from "./module/action";

export default function NavbarHome() {
  const userLogin = useSelector((state) => state.userLoginReducer.userLogin);
  const dispatch = useDispatch();
  return (
    <header>
      <nav className="navbar navbar-expand-md ">
        <NavLink className="navbar-brand" to={`/`}>
          <img className="logoheader" src={logo} />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i class="fas fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <div className="header__center">
              <li className="nav-item active">
                <a href="/#lichChieu">Lịch Chiếu</a>
              </li>
              <li className="nav-item">
                <a href="/#cumRap">Cụm Rạp</a>
              </li>
              <li className="nav-item dropdown">
                <a href="/#filmHot">Film Hot</a>
              </li>
              <li className="nav-item">
                <a href="/#tinTuc">Tin Tức</a>
              </li>
            </div>
            <div className="header__right">
              {userLogin ? (
                <div className="login">
                  <a
                    class=" dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLogin"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={boy} alt="avtLogin" />
                    {userLogin.hoTen}
                  </a>
                  <span
                    className="logout"
                    onClick={() => dispatch(actLogout())}
                  >
                    Đăng xuất
                  </span>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLogin"
                  >
                    <NavLink class="dropdown-item" to={"/user/thongtincanhan"}>
                      Thông tin cá nhân
                    </NavLink>
                    <NavLink class="dropdown-item" to={"/user/thaydoimatkhau"}>
                      Thay đổi mật khẩu
                    </NavLink>
                    <NavLink class="dropdown-item" to={"/user/lichsudatve"}>
                      Lịch sử đặt vé
                    </NavLink>
                  </div>
                </div>
              ) : (
                <div className="none-login">
                  <Link to={"/login"} className="dangnhap">
                    {" "}
                    <img src={avtLogin} alt="avtLogin" /> Đăng Nhập
                  </Link>
                </div>
              )}
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
}
