import * as constants from "./constants";
const initialState = {
  userLogin: "",
};

const userLoginReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.USER_LOGIN_SUCCESS:
      state.userLogin = payload;
      return { ...state };
    case constants.USER_CLEAR_DATA:
      state.userLogin = "";
      return { ...state };
    default:
      return { ...state };
  }
};
export default userLoginReducer;
