import React from "react";
import Slider from "./Slider";
import FilmBlock from "./filmBlock";
import CinemaBlock from "./cinemaBlock";
import FilmHot from "./filmHot";
import NewsBlock from "./newsBlock";
import Footer from "../../../components/footer";
import NavbarHome from "../../../components/NavbarHome";

export default function Home() {
  return (
    <div>
      <NavbarHome />
      <Slider />
      <FilmBlock />
      <CinemaBlock />
      <FilmHot />
      <NewsBlock />
      <Footer />
    </div>
  );
}
