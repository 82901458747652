import React from "react";
import { useState } from "react";
import ModalVideo from "react-modal-video";
import Carousel from "react-bootstrap/Carousel";
import nextImage from "../../../../assets/img/nextArrow.png";
import preImage from "../../../../assets/img/prevArrow.png";
import "./style.scss";
import { youTubeIdFromLink } from "../../../../helpers";
import sliderList from "../../../../data/sliderList.json";
import btnPlayer from "../../../../assets/img/play_64px.png";

const CarouselSlider = (props) => {
  const [index, setIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [iframe, setIframe] = useState(0);

  // console.log(iframe);

  const renderMovieList = () => {
    return sliderList.map((movie, idx) => {
      const { hinhAnh, trailer } = movie;
      return (
        <Carousel.Item key={idx}>
          <div
            style={{
              backgroundImage: `url('${hinhAnh}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              paddingBottom: "45%",
            }}
          ></div>
          <div className="carousel__overlay overlay">
            {/* <div className="carousel__overlay__play btn-play"> */}
            <a
              className="carousel_popup"
              href="#modal-carousel"
              data-toggle="modal"
              onClick={() => {
                setOpen(true);
                setIframe(trailer);
              }}
            >
              <img src={btnPlayer} alt="btn-play" />
            </a>
            {/* </div> */}
          </div>
        </Carousel.Item>
      );
    });
  };

  return (
    <section id="slider">
      <Carousel
        id="carouselCustomize"
        activeIndex={index}
        onSelect={handleSelect}
        prevIcon={
          <span className="carousel-control-btn">
            <img src={preImage} alt="pre-icon" />
          </span>
        }
        nextIcon={
          <span className="carousel-control-btn">
            <img src={nextImage} alt="next-icon" />
          </span>
        }
      >
        {renderMovieList()}
      </Carousel>

      {iframe ? (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId={youTubeIdFromLink(iframe)}
          onClose={() => setOpen(false)}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default CarouselSlider;
