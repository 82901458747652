import { filmManagementService } from "../../../../services";
import * as constants from "./constants";
import { createAction } from "../../../../helpers";
import Swal from "sweetalert2";

export const fetchListFilmManagement = (loading) => {
  return (dispatch) => {
    filmManagementService.fetchListFilmManagement().then((res) => {
      dispatch(createAction(constants.FETCH_LIST_FILM_SUCCESS, res.data));
      loading();
    });
  };
};
export const actAddFilmAPI = (data) => {
  const user = JSON.parse(localStorage.getItem("User"));
  return (dispatch) => {
    filmManagementService
      .actAddFilmAPI(data, user)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Thành công",
        });
        dispatch(createAction(constants.ACT_ADD_FILM_API_SUCCESS, res.data));
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Lỗi",
          text: `${err.response.data}`,
        });
      });
  };
};

export const actDeleteFilmAPI = (data) => {
  const token = JSON.parse(localStorage.getItem("User")).accessToken;
  console.log(token, data);
  return (dispatch) => {
    filmManagementService
      .actDeleteFilmAPI(data, token)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Xóa Thành công",
        }).then(() => {
          filmManagementService.fetchListFilmManagement().then((res) => {
            dispatch(createAction(constants.FETCH_LIST_FILM_SUCCESS, res.data));
          });
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Xóa không thành công",
          text: `${err.response.data}`,
        });
      });
  };
};

export const fetchFilmInfoAPI = (id, loading) => {
  return (dispatch) => {
    filmManagementService.fetchFilmInfoAPI(id).then((res) => {
      dispatch(createAction(constants.FETCH_FILM_INFO_SUCCESS, res.data));
      loading();
    });
  };
};

export const actEditFilmAPI = (data, history) => {
  const user = JSON.parse(localStorage.getItem("User"));
  return () => {
    filmManagementService
      .actEditFilmAPI(data, user)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Chỉnh sửa thành công",
        }).then(() => {
          history.push("/admin");
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Chỉnh sửa thất bại",
          text: `${err.response.data}`,
        });
      });
  };
};
export const findMovieAPI = (data) => {
  if (data) {
    return (dispatch) => {
      filmManagementService
        .fetchListFilmManagementByName(data)
        .then((res) => {
          dispatch(createAction(constants.FETCH_LIST_FILM_SUCCESS, res.data));
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data}`,
          });
        });
    };
  } else {
    return (dispatch) => {
      filmManagementService
        .fetchListFilmManagement()
        .then((res) => {
          dispatch(createAction(constants.FETCH_LIST_FILM_SUCCESS, res.data));
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data}`,
          });
        });
    };
  }
};
