export const GET_CINEMA_SYSTEM_INFOMATION_SUCCESS =
  "GET_CINEMA_SYSTEM_INFOMATION_SUCCESS";
export const GET_CINEMA_SYSTEM_INFOMATION_FAILED =
  "GET_CINEMA_SYSTEM_INFOMATION_FAILED";
export const GET_CINEMA_SYSTEM_INFOMATION_REQUEST =
  "GET_CINEMA_SYSTEM_INFOMATION_REQUEST";

export const GET_CINEMA_CHILD_INFOMATION_SUCCESS =
  "GET_CINEMA_CHILD_INFOMATION_SUCCESS";
export const GET_CINEMA_CHILD_INFOMATION_FAILED =
  "GET_CINEMA_CHILD_INFOMATION_FAILED";
export const GET_CINEMA_CHILD_INFOMATION_REQUEST =
  "GET_CINEMA_CHILD_INFOMATION_REQUEST";

export const GET_SCHEDULE_CINEMA_SYSTEM_SUCCESS =
  "GET_SCHEDULE_CINEMA_SYSTEM_SUCCESS";
export const GET_SCHEDULE_CINEMA_SYSTEM_FAILED =
  "GET_SCHEDULE_CINEMA_SYSTEM_FAILED";
export const GET_SCHEDULE_CINEMA_SYSTEM_REQUEST =
  "GET_SCHEDULE_CINEMA_SYSTEM_REQUEST";
