import React from "react";
import "./style.scss";
import imgCombo from "../../../../assets/img/Combo/cgv_combo_mr_2020.jpg";
import { useDispatch, useSelector } from "react-redux";
import { changeComboNumber } from "../module/actions";

export default function Combo() {
  const dispatch = useDispatch();
  const comboList = useSelector((state) => state.bookingReducer.comboList);
  const renderComboItem = () => {
    return comboList.map((item) => {
      return (
        <div key={item.comboID} className="combo__item row">
          <div className="col-3 item__left">
            <img src={item.image} alt="" />
          </div>
          <div className="col-7 item__mid">
            <span>{item.comboName}</span>
            <p>
              {item.description} <br /> {item.note}
            </p>
            <p className="price">{item.price} đ</p>
          </div>
          <div className="col-2 item__right">
            <i
              onClick={() => dispatch(changeComboNumber(item, -1))}
              class="fa fa-minus"
            ></i>
            <span className="amount">{item.number}</span>
            <i
              onClick={() => dispatch(changeComboNumber(item, +1))}
              class="fa fa-plus"
            ></i>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className="modal fade booking__combo"
      id="comboModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Combo
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="combo__list container">{renderComboItem()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
