//Phim đang chiếu
export const FILM_SHOWING_REQUEST = "filmShowingReducer/FILM_SHOWING_REQUEST";
export const FILM_SHOWING_SUCCESS = "filmShowingReducer/FILM_SHOWING_SUCCESS";
export const FILM_SHOWING_FAILED = "filmShowingReducer/FILM_SHOWING_FAILED";
//Phim Sắp chiếu
export const FILM_UPCOMING_REQUEST =
  "FilmUpcomingReducer/FILM_UPCOMING_REQUEST";
export const FILM_UPCOMING_SUCCESS =
  "FilmUpcomingReducer/FILM_UPCOMING_SUCCESS";
export const FILM_UPCOMING_FAILED = "FilmUpcomingReducer/FILM_UPCOMING_FAILED";
