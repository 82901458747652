import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Redirect, Route } from "react-router";
import { NavLink } from "react-router-dom";
import MovieIcon from "@material-ui/icons/Movie";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AdminLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "rgba(50,50,50,0.9)" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <NavLink
              to={"/admin/movie"}
              style={{
                color: "#60c5ef",
                fontSize: "1.5rem",
              }}
            >
              ADMIN
            </NavLink>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
            <span style={{}}>Dashboard</span>
          </IconButton>
        </div>
        <Divider />
        <List>
          <NavLink
            to={"/admin/movie"}
            activeStyle={{
              color: "#005bff",
            }}
          >
            <ListItem key={"Quản lý phim"}>
              <MovieIcon style={{ marginRight: "32px" }} />
              <ListItemText primary={"Quản lý phim"} />
            </ListItem>
          </NavLink>
          <NavLink
            to={"/admin/user"}
            activeStyle={{
              color: "#005bff",
            }}
          >
            <ListItem key={"Thành viên"}>
              <SupervisorAccountIcon style={{ marginRight: "32px" }} />
              <ListItemText primary={"Thành viên"} />
            </ListItem>
          </NavLink>
        </List>
        <Divider />
        <NavLink to={"/"}>
          <ListItem>
            <ExitToAppIcon style={{ marginRight: "32px", color: "red" }} />
            <ListItemText primary={"Về trang chủ"} />
          </ListItem>
        </NavLink>
      </Drawer>
      <main
        className={classes.content}
        style={{ minHeight: "100vh", backgroundColor: "rgba(0, 0,0,0.2)" }}
      >
        <div className={classes.toolbar} />
        <Typography paragraph>{props.children}</Typography>
      </main>
    </div>
  );
}

export default function AdminTemplate(props) {
  const { exact, path, component } = props;
  if (!localStorage.getItem("User")) return <Redirect to="/auth" />;
  const userAdmin = JSON.parse(localStorage.getItem("User"));
  if (userAdmin.maLoaiNguoiDung !== "QuanTri") return <Redirect to="/auth" />;
  return (
    <AdminLayout>
      <Route exact={exact} path={path} component={component} />
    </AdminLayout>
  );
}
