import React, { useEffect, useState } from "react";
import screenImg from "../../../assets/img/others/screen.png";
import popcorn from "../../../assets/img/icon/popcorn.png";
import "./style.scss";
import Combo from "./Combo";
import { useDispatch, useSelector } from "react-redux";
import { actBookTicketAPI, fetchTickketRoom } from "./module/actions";
import Loader from "../../../components/Loader";
import { useHistory, useParams } from "react-router-dom";
import Seat from "./Seat";
import NavbarHome from "../../../components/NavbarHome";
import CountDown from "./CountDown";

export default function Booking() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isBookTicket, setBookTicket] = useState({
    maLichChieu: 0,
    danhSachVe: [
      {
        maGhe: 0,
        giaVe: 0,
      },
    ],
    taiKhoanNguoiDung: "",
  });
  useEffect(() => {
    dispatch(fetchTickketRoom(id, () => setLoading(false)));
  }, []);

  // lấy ticketRoom
  const ticketRoom = useSelector((state) => state.bookingReducer.ticketRoom);
  // lấy thông tin phim
  const filmInfo = useSelector(
    (state) => state.bookingReducer.ticketRoom.thongTinPhim
  );
  // lấy danh sách ghế
  const seatList = useSelector(
    (state) => state.bookingReducer.ticketRoom.danhSachGhe
  );
  // lấy danh sách ghế đã được chọn
  const selectedSeatList = useSelector(
    (state) => state.bookingReducer.selectedSeatList
  );
  // lấy comboList
  const comboList = useSelector((state) => state.bookingReducer.comboList);

  // lấy user từ localstorage
  const userInfo = JSON.parse(localStorage.getItem("User"));

  // handle effect
  useEffect(() => {
    setBookTicket(createBookingInfo());
  }, [selectedSeatList]);
  const createBookingInfo = () => {
    const seatList = [];
    selectedSeatList?.forEach((item) => {
      const seatTemp = {
        maGhe: item.maGhe,
        giaVe: item.giaVe,
      };
      seatList.push(seatTemp);
    });
    return {
      maLichChieu: filmInfo?.maLichChieu,
      danhSachVe: seatList,
      taiKhoanNguoiDung: userInfo?.taiKhoan,
    };
  };

  // LOADING
  if (isLoading) {
    return <Loader />;
  }

  // function
  const renderCinemaHeader = () => {
    return (
      <div className="cinema__wrap">
        <div className="cinema__img">
          <img
            src={`/img/logo/${filmInfo?.tenCumRap
              .split("-")[0]
              .replace(/\s/g, "")}.png`}
            alt="img"
          />
        </div>
        <div className="cinema__text">
          <h6>
            <span>{filmInfo?.tenCumRap.split("-")[0]}- </span>{" "}
            {filmInfo?.tenCumRap.split("-")[1]}
          </h6>
          <p>
            {filmInfo?.ngayChieu} - {filmInfo?.gioChieu} - {filmInfo?.tenRap}
          </p>
        </div>
      </div>
    );
  };
  const renderSeatList = () => {
    if (seatList && seatList.length > 0) {
      return seatList.map((item) => {
        return <Seat key={item.maGhe} seatItem={item} />;
      });
    }
  };
  const renderSelectedSeatList = () => {
    return selectedSeatList?.map((item) => {
      return <span key={item.maGhe}>{item.tenGhe} </span>;
    });
  };
  const renderSeatPrice = () => {
    return selectedSeatList?.reduce((total, item) => {
      return (total += item.giaVe);
    }, 0);
  };
  const renderComboPrice = () => {
    return comboList?.reduce((total, item) => {
      return (total += item.price * item.number);
    }, 0);
  };

  // handle book ticket
  const handleBookTicket = () => {
    dispatch(actBookTicketAPI(isBookTicket, userInfo.accessToken, history));
  };

  return (
    <div>
      <NavbarHome />
      <div className="booking container-fluid">
        <div className="row">
          <div className="booking__left col-sm-9 col-12">
            <div className="booking__left-header">
              <div className="cinema__left">{renderCinemaHeader()}</div>
              <div className="cinema__right">
                <div className="timer">
                  <h6>Thời gian giữ ghế</h6>

                  <p>
                    <CountDown time={300000} history={history} />
                  </p>
                </div>
              </div>
            </div>
            <div className="booking__left-room">
              <div className="room__screen">
                <div className="screen__img">
                  <img src={screenImg} alt="" />
                </div>
              </div>
              <div className="room__seat">
                <div className="seat__wrap">{renderSeatList()}</div>
              </div>
              <div className="room__note">
                <div className="note__item">
                  <p></p>
                  <span>Thường</span>
                </div>
                <div className="note__item">
                  <p className="seat--gold"></p>
                  <span>Vip</span>
                </div>
                <div className="note__item">
                  <p className="seat--green"></p>
                  <span>Ghế đang chọn</span>
                </div>
                <div className="note__item">
                  <p className="seat--red">x</p>
                  <span>Ghế đã có người chọn</span>
                </div>
              </div>
            </div>
          </div>
          <div className="booking__right col-sm-3 col-12">
            <div className="booking__right-content">
              <div className="price-total text-center">
                Tổng hóa đơn <br /> {renderSeatPrice() + renderComboPrice()} đ
              </div>
              <div className="booking__right-movie">
                <h6>{filmInfo?.tenPhim}</h6>
                <p>{filmInfo?.tenCumRap}</p>
                <span>
                  {filmInfo?.ngayChieu} - {filmInfo?.gioChieu} -{" "}
                  {filmInfo?.tenRap}
                </span>
              </div>
              <div className="booking__right-seat row">
                <span className="col-6">Ghế: {renderSelectedSeatList()}</span>
                <p className="seat__price col-6">{renderSeatPrice()} đ</p>
              </div>
              <div
                className="booking__right-combo"
                data-toggle="modal"
                data-target="#comboModal"
              >
                <div className="combo__img">
                  <i class="fas fa-angle-right"></i>
                  <img src={popcorn} alt="popcorn" />
                </div>
                <div className="combo__price">{renderComboPrice()} đ</div>
              </div>
              {/* <div className="booking__right-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="phoneNumber"
                  class="form-control"
                  placeholder="Số điện thoại"
                />
              </div>
            </div> */}
            </div>
            <div className="booking__right-purchase">
              <button
                id="buy-ticket"
                disabled={selectedSeatList?.length > 0 ? false : true}
                className="btn btn-purchase"
                onClick={handleBookTicket}
              >
                ĐẶT VÉ
              </button>
            </div>
          </div>
        </div>
        <Combo />
      </div>
    </div>
  );
}
