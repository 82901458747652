import { movieService } from "../../../../services/index";
import { createAction } from "../../../../helpers";
import * as constants from "./constants";
export const getMovieListRequest = () => {
  return (dispatch) => {
    movieService
      .getMovieList()
      .then((res) => {
        dispatch(createAction(constants.GET_MOVIE_LIST_SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(createAction(constants.GET_MOVIE_LIST_FAILED, err));
      });
  };
};
export const getMovieDetailRequest = (id, callback) => {
  return (dispatch) => {
    movieService
      .getMovieDetail(id)
      .then((res) => {
        dispatch(createAction(constants.GET_MOVIE_DETAIL_SUCCESS, res.data));
        callback();
      })
      .catch((err) => {
        dispatch(createAction(constants.GET_MOVIE_DETAIL_FAILED, err));
        // callbackError();
      });
  };
};
