import React, { useState } from "react";
import logo from "../../../assets/img/logo/booking-movie-logo.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as formik from "formik";
import "./style.scss";
import { resgisterSchema } from "../../../helpers";
import { actRegisterAPI } from "./module/action";

export default function Register() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(actRegisterAPI(values, history));
  };
  const customError = (msg) => {
    return <div className="text-danger">{msg}</div>;
  };

  return (
    <div id="register" className="register">
      <div className="register__wrapper">
        <div className="register__logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>

          <p>Mang cả thế giới phim đến với bạn</p>
        </div>
        <formik.Formik
          initialValues={{
            taiKhoan: "",
            matKhau: "",
            email: "",
            soDt: "",
            maNhom: "GP01",
            maLoaiNguoiDung: "KhachHang",
            hoTen: "",
          }}
          validationSchema={resgisterSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <formik.Form className="register__main">
              <div className="form-group">
                <label>Tài khoản</label>
                <formik.Field
                  type="text"
                  className="form-control"
                  name="taiKhoan"
                  onChange={formikProps.handleChange}
                />
                <formik.ErrorMessage name="taiKhoan">
                  {(msg) => customError(msg)}
                </formik.ErrorMessage>
              </div>
              <div className="form-group">
                <label>Mật khẩu</label>
                <formik.Field
                  type="password"
                  className="form-control"
                  name="matKhau"
                  onChange={formikProps.handleChange}
                />
                <formik.ErrorMessage name="matKhau">
                  {(msg) => customError(msg)}
                </formik.ErrorMessage>
              </div>
              <div className="form-group">
                <label>Họ tên</label>
                <formik.Field
                  type="text"
                  className="form-control"
                  name="hoTen"
                  onChange={formikProps.handleChange}
                />
                <formik.ErrorMessage name="hoTen">
                  {(msg) => customError(msg)}
                </formik.ErrorMessage>
              </div>
              <div className="form-group">
                <label>Email</label>
                <formik.Field
                  type="email"
                  className="form-control"
                  name="email"
                  onChange={formikProps.handleChange}
                />
                <formik.ErrorMessage name="email">
                  {(msg) => customError(msg)}
                </formik.ErrorMessage>
              </div>
              <div className="form-group">
                <label>Số điện thoại</label>
                <formik.Field
                  type="text"
                  className="form-control"
                  name="soDt"
                  onChange={formikProps.handleChange}
                />
                <formik.ErrorMessage name="soDt">
                  {(msg) => customError(msg)}
                </formik.ErrorMessage>
              </div>
              <div className="form-group">
                <label>Mã nhóm</label>
                <select
                  className="form-control"
                  name="maNhom"
                  id=""
                  onChange={formikProps.handleChange}
                >
                  <option>GP01</option>
                  <option>GP02</option>
                  <option>GP03</option>
                  <option>GP04</option>
                  <option>GP05</option>
                  <option>GP06</option>
                  <option>GP07</option>
                  <option>GP08</option>
                  <option>GP09</option>
                  <option>GP010</option>
                </select>
              </div>

              <button type="submit" className="btn">
                Đăng ký
              </button>
            </formik.Form>
          )}
        </formik.Formik>
        <Link to={"/"} className="register__close">
          <i class="fa fa-times"></i>
        </Link>
        <div className="register__redirect-login">
          <span>Bạn đã có tài khoản?</span>
          <Link to={"/login"} className="signin">
            Đăng nhập
          </Link>
        </div>
      </div>
    </div>
  );
}
