import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  actUpdateInformationAPI,
  fetchUserInformationAPI,
} from "../module/action";
import Loader from "../../../components/Loader";

export default function ChangePasswordUser() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("User"));
    dispatch(fetchUserInformationAPI(user.taiKhoan, () => setLoading(false)));
  }, []);
  const { maLoaiNguoiDung } = useSelector(
    (state) => state.userLoginReducer.userLogin
  );
  const user = useSelector((state) => state?.userReducer?.userInfo);
  const alertError = (err) => {
    return (
      <div
        style={{ fontWeight: 500, textAlign: "left" }}
        className="text-danger"
      >
        {err}
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },

    validate: (values) => {
      const err = {};
      if (!values.oldPassword) {
        err.oldPassword = "* Bạn chưa nhập mật khẩu cũ";
      } else if (values.oldPassword !== user.matKhau) {
        err.oldPassword = "* Mật khẩu không chính xác";
      }

      if (!values.newPassword) {
        err.newPassword = "* Bạn chưa nhập mật khẩu mới";
      }

      if (!values.newPasswordConfirm) {
        err.newPasswordConfirm = "* Bạn chưa nhập lại mật khẩu mới";
      } else if (values.newPasswordConfirm !== values.newPassword) {
        err.newPasswordConfirm = "Nhập lại không chính xác";
      }
      return err;
    },
    onSubmit: (values) => {
      const newPasswords = values.newPassword;
      const updatePassword = {
        hoTen: user.hoTen,
        taiKhoan: user.taiKhoan,
        soDt: user.soDT,
        email: user.email,
        maNhom: user.maNhom,
        maLoaiNguoiDung: maLoaiNguoiDung,
        matKhau: newPasswords,
      };
      dispatch(actUpdateInformationAPI(updatePassword));
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="wrapper container">
      <div className="header">
        <h2 className="title">Thay đổi mật khẩu</h2>
      </div>
      <form className="content" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="">Mật khẩu cũ</label>
          <input
            type="password"
            className="form-control"
            onChange={formik.handleChange}
            name="oldPassword"
            onBlur={formik.handleBlur}
          />
          {formik.errors.oldPassword &&
            formik.touched.oldPassword &&
            alertError(formik.errors.oldPassword)}
        </div>
        <div className="form-group">
          <label htmlFor="">Mật khẩu mới</label>
          <input
            id="newPassword"
            type="password"
            className="form-control"
            onChange={formik.handleChange}
            name="newPassword"
            onBlur={formik.handleBlur}
          />
          {formik.errors.newPassword &&
            formik.touched.newPassword &&
            alertError(formik.errors.newPassword)}
        </div>
        <div className="form-group">
          <label htmlFor="">Nhập lại mật khẩu mới</label>
          <input
            id="newPasswordConfirm"
            type="password"
            className="form-control"
            onChange={formik.handleChange}
            name="newPasswordConfirm"
            onBlur={formik.handleBlur}
          />
          {formik.errors.newPasswordConfirm &&
            formik.touched.newPasswordConfirm &&
            alertError(formik.errors.newPasswordConfirm)}
        </div>
        <button type="submit" className="btn btn-update">
          Thay đổi mật khẩu
        </button>
      </form>
    </div>
  );
}
