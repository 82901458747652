import { Redirect, Route } from "react-router";

function LayoutProtectdRoute(props) {
  return <div>{props.children}</div>;
}

const ProtectedRoute = (props) => {
  const { exact, path, component } = props;
  if (localStorage.getItem("User")) {
    return (
      <LayoutProtectdRoute>
        <Route exact={exact} path={path} component={component} />
      </LayoutProtectdRoute>
    );
  } else {
    return <Redirect to={"/login"} />;
  }
};
export default ProtectedRoute;
