import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { youTubeIdFromLink } from "../../../../helpers";
import "./style.scss";

export default function FilmHot() {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="filmHot" id="filmHot">
      <img
        className="background-img"
        src="https://d2fy2et424xkoh.cloudfront.net/blog/wp-content/uploads/2019/04/Banner-for-endgame.jpg"
        alt=""
      />
      <div className="containers">
        <div className="row">
          <div className="col">
            <div className="circle">
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId={`${youTubeIdFromLink(
                  "https://www.youtube.com/watch?v=TcMBFSGVi1c"
                )}`}
                onClose={() => setOpen(false)}
              />
              <button className="btn btn-player" onClick={() => setOpen(true)}>
                <i class="fas fa-play"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
