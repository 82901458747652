import { bookingService } from "../../../../services";
import { createAction } from "../../../../helpers";
import * as constants from "./contants";
import Swal from "sweetalert2";

export const fetchTickketRoom = (id, callback) => {
  return (dispatch) => {
    bookingService
      .fetchTicketRoom(id)
      .then((res) => {
        dispatch(createAction(constants.FETCH_TICKET_ROOM_SUCCESS, res.data));
        callback();
      })
      .catch((err) => {
        dispatch(createAction(constants.FETCH_TICKET_ROOM_FAIL, err));
      });
  };
};

export const actBookTicketAPI = (data, token, history) => {
  return (dispatch) => {
    bookingService
      .actBookTicketAPI(data, token)
      .then((res) => {
        dispatch(createAction(constants.BOOK_TICKET_SUCCESS, res.data));
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          history.push("/user/lichsudatve");
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Đặt vé thất bại",
          text: "Vui lòng thử lại",
        });
        dispatch(createAction(constants.BOOK_TICKET_FAIL, err));
      });
  };
};

export const actPushSeat = (data) => {
  return {
    type: constants.PUSH_SEAT,
    payload: data,
  };
};

export const changeComboNumber = (data, flag) => {
  return {
    type: constants.CHANGE_COMBO_NUMBER,
    payload: data,
    flag,
  };
};
