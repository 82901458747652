import { Paper } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MovieList from "./MovieList";
import "./style.scss";
import { findMovieAPI } from "./module/action";

export default function MovieManagement() {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    dispatch(findMovieAPI(value));
  };
  return (
    <Paper className="movieAddAdmin">
      <div className="container-fluid">
        <div className="rows">
          <div className=" item">
            <Link to="/admin/addmovie" className="addMovie__link">
              <button className="btn" style={{ margin: "10px 5px" }}>
                ADD MOVIE
              </button>
            </Link>
          </div>
          <div className="item">
            <form className="form-inline">
              <div className="form-group">
                <input
                  id="add"
                  type="search"
                  placeholder="Search by name"
                  className="form-control"
                  onChange={handleChange}
                  name="search"
                ></input>
                <i class="fas fa-search"></i>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MovieList />
    </Paper>
  );
}
