import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actPushSeat } from "../module/actions";

export default function Seat(props) {
  const dispatch = useDispatch();
  const { seatItem } = props;
  const [isBooking, setBooking] = useState(true);

  const colorSeat = () => {
    if (seatItem.daDat) {
      return "seat--red";
    } else if (seatItem.loaiGhe === "Thuong") {
      return "seat--black";
    } else if (seatItem.loaiGhe === "Vip") {
      return "seat--gold";
    }
  };

  const changeColor = (e) => {
    e.target.classList.toggle("seat--green");
    setBooking(!isBooking);
    const seat = {
      seatItem: seatItem,
      booking: isBooking,
    };
    dispatch(actPushSeat(seat));
  };
  return (
    <div className={`seat__item ${colorSeat()}`} onClick={changeColor}>
      {seatItem.daDat ? "x" : seatItem.tenGhe}
    </div>
  );
}
