import * as constants from "./constants";

const initialState = {
  userInfo: "",
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_USER_INFORMATION_SUCCESS:
      state.userInfo = payload;
      return { ...state };
    default:
      return { ...state };
  }
};
export default userReducer;
