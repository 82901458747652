import axios from "axios";

class FilmManagementService {
  fetchListFilmManagement = () => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhim?maNhom=GP10",
      method: "GET",
    });
  };
  fetchListFilmManagementByName = (data) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhim?maNhom=GP10&tenPhim=${data}`,
      method: "GET",
    });
  };
  fetchFilmInfoAPI = (id) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayThongTinPhim?MaPhim=${id}`,
      method: "GET",
    });
  };
  actAddFilmAPI = (data, user) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/ThemPhim",
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
  };
  actDeleteFilmAPI = (data, token) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/XoaPhim?MaPhim=${data}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  actEditFilmAPI = (data, user) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/CapNhatPhim",
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
  };
}
export default FilmManagementService;
