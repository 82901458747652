import axios from "axios";
import { createAction } from "../../../../../helpers";
import { filmService } from "../../../../../services";
import * as constants from "./constants";

export const actFetchFilmShowing = () => {
  return (dispatch) => {
    dispatch(createAction(constants.FILM_SHOWING_REQUEST));
    filmService
      .fetchFilmShowing()
      .then((res) => {
        dispatch(createAction(constants.FILM_SHOWING_SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(createAction(constants.FILM_SHOWING_FAILED, err));
      });
  };
};

export const actFetchFilmUpComing = () => {
  return (dispatch) => {
    dispatch(createAction(constants.FILM_UPCOMING_REQUEST));
    filmService
      .fetchFilmUpcoming()
      .then((res) => {
        dispatch(createAction(constants.FILM_UPCOMING_SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(createAction(constants.FILM_UPCOMING_FAILED, err));
      });
  };
};
