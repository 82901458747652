import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { postFilmSchema } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { actEditFilmAPI, fetchFilmInfoAPI } from "../module/action";
import { useHistory, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import Loader from "../../../../components/Loader";

export default function EditMovie() {
  const [isLoading, setLoading] = useState(true);
  const idFilm = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchFilmInfoAPI(idFilm.id, () => setLoading(false)));
  }, []);

  const filmDetail = useSelector((state) => state?.filmManagement?.filmInfo);
  const handleAlertError = (err) => {
    return <div className="text-danger">{err}</div>;
  };

  const formik = useFormik({
    initialValues: {
      maPhim: filmDetail.maPhim,
      tenPhim: filmDetail.tenPhim,
      biDanh: filmDetail?.biDanh,
      trailer: filmDetail?.trailer,
      hinhAnh: filmDetail?.hinhAnh,
      moTa: filmDetail?.moTa,
      maNhom: filmDetail?.maNhom,
      ngayKhoiChieu: dateFormat(filmDetail?.ngayKhoiChieu, "dd/mm/yyyy"),
      danhGia: filmDetail?.danhGia,
    },
    enableReinitialize: true,
    validationSchema: postFilmSchema,

    onSubmit: (values) => {
      dispatch(actEditFilmAPI(values, history));
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="container addMovie">
      <h2 className="title">EDIT MOVIE</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Tên phim</label>
              <input
                type="text"
                className="form-control"
                name="tenPhim"
                value={formik.values.tenPhim}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.tenPhim &&
                formik.touched.tenPhim &&
                handleAlertError(formik.errors.tenPhim)}
            </div>
            <div className="form-group">
              <label>Trailer</label>
              <input
                type="text"
                className="form-control"
                name="trailer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.trailer}
              />
              {formik.errors.trailer &&
                formik.touched.trailer &&
                handleAlertError(formik.errors.trailer)}
            </div>
            <div className="form-group">
              <label>Bí danh</label>
              <input
                type="text"
                className="form-control"
                name="biDanh"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.biDanh}
              />
              {formik.errors.biDanh &&
                formik.touched.biDanh &&
                handleAlertError(formik.errors.biDanh)}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Ngày khởi chiếu</label>
              <input
                type="text"
                className="form-control"
                name="ngayKhoiChieu"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ngayKhoiChieu}
              />
              {formik.errors.ngayKhoiChieu &&
                formik.touched.ngayKhoiChieu &&
                handleAlertError(formik.errors.ngayKhoiChieu)}
            </div>
            <div className="form-group">
              <label>Đánh giá</label>
              <input
                type="number"
                className="form-control"
                name="danhGia"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.danhGia}
              />
              {formik.errors.danhGia &&
                formik.touched.danhGia &&
                handleAlertError(formik.errors.danhGia)}
            </div>
            <div className="form-group">
              <label>Hình ảnh</label>
              <input
                type="text"
                className="form-control"
                name="hinhAnh"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hinhAnh}
              />
              {formik.errors.hinhAnh &&
                formik.touched.hinhAnh &&
                handleAlertError(formik.errors.hinhAnh)}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Mô tả</label>
              <textarea
                rows="5"
                type="text"
                className="form-control"
                name="moTa"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.moTa}
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
}
