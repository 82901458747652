import React, { useEffect, useState } from "react";
import "./style.scss";
import Slider from "react-slick";
import star1 from "../../../../assets/img/icon/star1.png";
import star12 from "../../../../assets/img/icon/star1.2.png";
import { connect } from "react-redux";
import { actFetchFilmShowing, actFetchFilmUpComing } from "./module/actions";
// import "../../../../../node_modules/react-modal-video/scss/modal-video.scss";
import { NavLink } from "react-router-dom";

import ModalVideo from "react-modal-video";
import { youTubeIdFromLink } from "../../../../helpers";

function FilmBlock(props) {
  const { filmShowing, filmUpcoming, dispatch } = props;

  const [isOpen, setOpen] = useState(false);
  const [isUrl, setUrl] = useState("");
  useEffect(() => {
    dispatch(actFetchFilmShowing());
    dispatch(actFetchFilmUpComing());
  }, []);
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    rows: 2,
    // slidesToShow: 2,
    slidesPerRow: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 678,
        settings: {
          slidesPerRow: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
    ],
  };
  const renderFilmShowing = () => {
    if (filmShowing && filmShowing.length > 0) {
      return filmShowing.map((item, index) => {
        return (
          <div href className="item" key={item.maPhim}>
            <div className="film">
              <div className="film-block-images">
                <img className="thumbnail" src={item.hinhAnh} alt="img" />
              </div>
              <div className="film-type"></div>
              <div className="film-point">
                <p>8.1</p>
                <div className="setStar">
                  <img className="smallStar" src={star1} alt="img" />
                  <img className="smallStar" src={star1} alt="img" />
                  <img className="smallStar" src={star1} alt="img" />
                  <img className="smallStar" src={star1} alt="img" />
                  <img src={star12} alt="img" className="halfStar" />
                </div>
              </div>
            </div>
            <div className="film-info">
              <p className="nameFilm">
                <span className="ageType">C13</span>
                {item.tenPhim}
              </p>
            </div>
            <div className="ul">
              <div className="li-1">
                <button
                  className="btn button-player"
                  onClick={() => {
                    setOpen(true);
                    setUrl(youTubeIdFromLink(item.trailer));
                  }}
                >
                  XEM TRAILER
                </button>
              </div>
              <div className="li-2">
                <NavLink
                  to={`/detail/${item.maPhim}`}
                  className="btn button-purchase"
                >
                  MUA VÉ
                </NavLink>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const renderFilmUpcoming = () => {
    if (filmUpcoming && filmUpcoming.length > 0) {
      return filmUpcoming.map((item, index) => {
        return (
          <div className="item" key={item.maPhim}>
            <div className="film">
              <div className="film-block-images">
                <img className="thumbnail" src={item.hinhAnh} alt="img" />
              </div>
            </div>
            <div className="film-info">
              <p className="nameFilm">
                <span className="ageType">C13</span>
                {item.tenPhim}
              </p>
            </div>
            <div className="ul">
              <div className="li-1">
                <button
                  className="btn button-player"
                  onClick={() => {
                    setOpen(true);
                    setUrl(youTubeIdFromLink(item.trailer));
                  }}
                >
                  XEM TRAILER
                </button>
              </div>
              {/* <div className="li-2">
                <button className="btn button-purchase">MUA VÉ</button>
              </div> */}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <section className="filmBlock" id="lichChieu">
      <React.Fragment>
        <ModalVideo
          autoplay="0"
          isOpen={isOpen}
          videoId={isUrl}
          onClose={() => setOpen(false)}
          mute="1"
        />
      </React.Fragment>
      <div className="container">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#nowShowing"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Đang chiếu
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#comingSoon"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Sắp chiếu
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="nowShowing"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <Slider {...settings}>{renderFilmShowing()}</Slider>
          </div>
          <div
            className="tab-pane fade"
            id="comingSoon"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Slider {...settings}>{renderFilmUpcoming()}</Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    filmShowing: state.filmShowingReducer.filmShowing,
    filmUpcoming: state.filmUpcomingReducer.filmUpcoming,
  };
};
export default connect(mapStateToProps)(FilmBlock);
