import * as constants from "./constant";
const initialState = {
  cinemaSystemList: null,
  cinemaChildList: null,
  cinemaSchedule: null,
};

const cinemaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_CINEMA_SYSTEM_INFOMATION_SUCCESS:
      state.cinemaSystemList = payload;
      return { ...state };
    case constants.GET_CINEMA_CHILD_INFOMATION_SUCCESS:
      state.cinemaChildList = payload;
      return { ...state };
    case constants.GET_SCHEDULE_CINEMA_SYSTEM_SUCCESS:
      state.cinemaSchedule = payload;
      return { ...state };
    default:
      return { ...state };
  }
};

export default cinemaReducer;
