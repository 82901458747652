import * as constants from "./contants";
import comboListData from "../../../../data/dataCombo.json";
const initialState = {
  ticketRoom: {},
  selectedSeatList: [],
  comboList: comboListData,
};

const bookingReducer = (state = initialState, action) => {
  const { type, payload, flag } = action;
  switch (type) {
    case constants.FETCH_TICKET_ROOM_SUCCESS:
      state.ticketRoom = payload;
      return { ...state };
    case constants.PUSH_SEAT:
      if (payload.booking) {
        state.selectedSeatList = [...state.selectedSeatList, payload.seatItem];
      } else {
        state.selectedSeatList = state.selectedSeatList.filter((item) => {
          return item.maGhe !== payload.seatItem.maGhe;
        });
      }
      return { ...state };
    case constants.CHANGE_COMBO_NUMBER:
      const index = state.comboList.findIndex((item) => {
        return item.comboID === payload.comboID;
      });
      if (index !== -1) {
        const comboTemp = state.comboList[index];

        if (flag === 1) {
          comboTemp.number++;
        } else {
          if (comboTemp.number > 0) {
            comboTemp.number--;
          }
        }
        const combo = [...state.comboList];
        combo[index] = comboTemp;
        state.comboList = combo;
      }

      return { ...state };
    default:
      return { ...state };
  }
};

export default bookingReducer;
