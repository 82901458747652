import axios from "axios";

export default class UserService {
  actLoginAPI = (data) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/DangNhap",
      method: "POST",
      data,
    });
  };
  actRegisterAPI = (data) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/DangKy",
      data,
      method: "post",
    });
  };
  fetchUserInformationAPI = (data) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/ThongTinTaiKhoan",
      method: "POST",
      data: { taiKhoan: data },
    });
  };
  actUpdateInformationAPI = (data, user) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/CapNhatThongTinNguoiDung",
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
  };
}
