import * as constants from "./constants";

const initialState = {
  userList: [],
  userInfo: {},
};
const userManagement = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_LIST_USER_MANAGEMENT_API_SUCCESS:
      state.userList = payload;
      return { ...state };
    case constants.FETCH_USER_INFO_MANAGEMENT_API_SUCCESS:
      state.userInfo = payload;
      return { ...state };
    default:
      return { ...state };
  }
};
export default userManagement;
