import * as constants from "./constants";
const initialState = {
  filmList: [],
  filmInfo: {},
};

const filmManagement = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_LIST_FILM_SUCCESS:
      state.filmList = payload;
      return { ...state };
    case constants.FETCH_FILM_INFO_SUCCESS:
      state.filmInfo = payload;
      return { ...state };
    default:
      return { ...state };
  }
};

export default filmManagement;
