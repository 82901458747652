import axios from "axios";

export default class BookingService {
  fetchTicketRoom = (id) => {
    return axios({
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyDatVe/LayDanhSachPhongVe?MaLichChieu=${id}`,
      method: "GET",
    });
  };
  actBookTicketAPI = (data, token) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyDatVe/DatVe",
      method: "POST",
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}
