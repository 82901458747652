import * as constants from "./constants";
const initialState = {
  loading: false,
  filmShowing: [],
  filmUpcoming: [],
  err: null,
};


export const filmShowingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FILM_SHOWING_REQUEST:
      state.loading = true;
      state.filmShowing = [];
      state.filmUpcoming = [];
      state.err = null;
      return { ...state };
    case constants.FILM_SHOWING_SUCCESS:
      state.loading = false;
      state.filmShowing = action.payload;
      state.filmUpcoming = [];
      state.err = null;
      return { ...state };
    case constants.FILM_SHOWING_FAILED:
      state.loading = false;
      state.filmShowing = [];
      state.filmUpcoming = [];
      state.err = action.payload;
      return { ...state };
    default:
      return { ...state };
  }
};

export const filmUpcomingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FILM_UPCOMING_REQUEST:
      state.err = null;
      state.filmUpcoming = [];
      state.filmShowing = [];
      state.loading = true;
      return { ...state };
    case constants.FILM_UPCOMING_SUCCESS:
      state.err = null;
      state.filmUpcoming = action.payload;
      state.filmShowing = [];
      state.loading = false;
      return { ...state };
    case constants.FILM_UPCOMING_FAILED:
      state.err = action.payload;
      state.filmUpcoming = [];
      state.filmShowing = [];
      state.loading = false;
      return { ...state };
    default:
      return { ...state };
  }
};
