import { combineReducers } from "redux";
import {
  filmShowingReducer,
  filmUpcomingReducer,
} from "../../containers/HomeTemplate/Home/filmBlock/module/reducers";
import cinemaReducer from "../../containers/HomeTemplate/Home/cinemaBlock/module/reducer";
import movieReducer from "../../containers/HomeTemplate/Detail/module/reducers";
import bookingReducer from "../../containers/HomeTemplate/Booking/module/reducer";
import userLoginReducer from "../../containers/HomeTemplate/Login/module/reducer";
import userReducer from "../../containers/UserTemplate/module/reducer";
import filmManagement from "../../containers/AdminTemplate/MovieManagement/module/reducer";
import userManagement from "../../containers/AdminTemplate/UserManagement/module/reducer";

const reducer = combineReducers({
  filmShowingReducer,
  filmUpcomingReducer,
  cinemaReducer,
  movieReducer,
  bookingReducer,
  userLoginReducer,
  userReducer,
  filmManagement,
  userManagement,
});
export default reducer;
