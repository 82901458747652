import React, { useEffect, useState } from "react";
import "./style.scss";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../../../components/Loader";
import {
  deleteUserManagementAPI,
  fetchListUserManagementAPI,
} from "../module/action";

const columns = [
  { id: "taiKhoan", label: "Tài khoản" },
  { id: "matKhau", label: "Mật khẩu" },
  { id: "hoTen", label: "Họ tên" },
  { id: "email", label: "Email" },
  { id: "soDt", label: "Số điện thoại" },
  { id: "maLoaiNguoiDung", label: "Loại người dùng" },
  { id: "congCu", label: "Công cụ" },
];

export default function UserList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchListUserManagementAPI(() => setLoading(false)));
  }, []);
  const userList = useSelector((state) => state?.userManagement?.userList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderMovie = () => {
    return userList
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((item) => {
        return (
          <TableRow key={item.taiKhoan}>
            <TableCell>{item.taiKhoan}</TableCell>
            <TableCell>{item.matKhau}</TableCell>
            <TableCell>{item.hoTen}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>{item.soDt}</TableCell>
            <TableCell>{item.maLoaiNguoiDung}</TableCell>
            <TableCell>
              {
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip
                      title="Sửa Phim"
                      style={{ color: "#007b3e", paddingRight: "10px" }}
                    >
                      <Link to={`/admin/edituser/${item.taiKhoan}`}>
                        <i className="fa fa-edit hover-icon" />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Xóa Phim">
                      <div>
                        <i
                          style={{ cursor: "pointer", color: "#fb4226" }}
                          className="fa fa-trash-alt hover-icon"
                          onClick={() => {
                            Swal.fire({
                              icon: "question",
                              title: "Bạn chắc chứ?",
                              text: `Xóa tài khoản ${item.hoTen}`,
                              showCancelButton: true,
                            }).then((res) => {
                              if (res.isConfirmed) {
                                dispatch(
                                  deleteUserManagementAPI(item.taiKhoan)
                                );
                              }
                            });
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              }
            </TableCell>
          </TableRow>
        );
      });
  };

  if (isLoading) {
    <Loader />;
  }
  return (
    <Paper className="classesRoot container-fluid">
      <TableContainer className="classesContainer">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderMovie()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
