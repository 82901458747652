import React from "react";
import { Route } from "react-router-dom";

function LayoutHome(props) {
  return <div>{props.children}</div>;
}

export default function HomeTemplate(props) {
  const { exact, path, component } = props;
  return (
    <LayoutHome>
      <Route exact={exact} path={path} component={component} />
    </LayoutHome>
  );
}
