import React, { memo } from "react";
import Swal from "sweetalert2";
import Countdown, { zeroPad } from "react-countdown";

function CountDown(props) {
  const { time, history } = props;
  const refreshPage = () => {
    window.location.reload();
  };
  // Renderer callback with condition
  const rendererCountDown = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return Swal.fire({
        icon: "warning",
        title: "Hết thời gian",
        text: "Bạn muốn tiếp tục đặt vé?",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          refreshPage();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          history.push("/");
          refreshPage();
        }
      });
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  return (
    <Countdown
      date={Date.now() + time}
      renderer={rendererCountDown}
    ></Countdown>
  );
}
export default memo(CountDown);
