import axios from "axios";

class AuthService {
  loginUserAdminAPI = (data) => {
    return axios({
      url: "https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/DangNhap",
      method: "POST",
      data,
    });
  };
}
export default AuthService;
