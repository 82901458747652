import React from "react";
import avartar from "../../../assets/img/boy.png";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";

export default function UserSidebar() {
  const userInfo = useSelector((state) => state?.userReducer?.userInfo);
  return (
    <div className=" user-sidebar">
      <div className="sidebar">
        <div className="header">
          <div className="info">
            <img src={avartar} alt="avartar" className="avartar" />
            <p>{userInfo.taiKhoan}</p>
          </div>
        </div>
        <div className="content">
          <NavLink
            to="/user/thongtincanhan"
            className="styleNavLink"
            activeClassName="styleNavLinkActive"
          >
            <i className="fa fa-user-cog"></i>
            Thông tin cá nhân
          </NavLink>
          <NavLink
            to="/user/thaydoimatkhau"
            className="styleNavLink"
            activeClassName="styleNavLinkActive"
          >
            <i className="fa fa-lock"></i>
            Thay đổi mật khẩu
          </NavLink>
          <NavLink
            to="/user/lichsudatve"
            className="styleNavLink"
            activeClassName="styleNavLinkActive"
          >
            <i className="fa fa-history"></i>
            Lịch sử đặt vé
          </NavLink>
        </div>
      </div>
    </div>
  );
}
