import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { addUserSchema } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  editUserInfoManagementAPI,
  fetchUserInfoManagementAPI,
} from "../module/action";
import Loader from "../../../../components/Loader";
import { useHistory, useParams } from "react-router-dom";

export default function EditUser() {
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const userID = useParams();
  const dispatch = useDispatch();
  const handleAlertError = (err) => {
    return <div className="text-danger">{err}</div>;
  };
  useEffect(() => {
    dispatch(fetchUserInfoManagementAPI(userID.id, () => setLoading(false)));
  }, []);
  const userInfo = useSelector((state) => state?.userManagement?.userInfo);

  const formik = useFormik({
    initialValues: {
      taiKhoan: userInfo.taiKhoan,
      matKhau: userInfo.matKhau,
      email: userInfo.email,
      hoTen: userInfo.hoTen,
      soDt: userInfo.soDT,
      maLoaiNguoiDung: "KhachHang",
      maNhom: "GP10",
    },
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: (values) => {
      dispatch(editUserInfoManagementAPI(values, history));
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="container addMovie">
      <h2 className="title">EDIT USER</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Tài khoản</label>
              <input
                disabled
                style={{ color: "gray", cursor: "no-drop" }}
                type="text"
                className="form-control disabled"
                name="taiKhoan"
                value={formik.values.taiKhoan}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
              />
              {formik.errors.taiKhoan &&
                formik.touched.taiKhoan &&
                handleAlertError(formik.errors.taiKhoan)}
            </div>
            <div className="form-group">
              <label>Mật khẩu</label>
              <input
                type="text"
                className="form-control"
                name="matKhau"
                value={formik.values.matKhau}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.matKhau &&
                formik.touched.matKhau &&
                handleAlertError(formik.errors.matKhau)}
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email &&
                formik.touched.email &&
                handleAlertError(formik.errors.email)}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>Họ tên</label>
              <input
                type="text"
                className="form-control"
                name="hoTen"
                value={formik.values.hoTen}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.hoTen &&
                formik.touched.hoTen &&
                handleAlertError(formik.errors.hoTen)}
            </div>
            <div className="form-group">
              <label>Số điện thoại</label>
              <input
                type="text"
                className="form-control"
                name="soDt"
                value={formik.values.soDt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.soDt &&
                formik.touched.soDt &&
                handleAlertError(formik.errors.soDt)}
            </div>
            <div className="form-group">
              <label>Loại người dùng</label>
              <select
                className="form-control"
                name="maLoaiNguoiDung"
                onChange={formik.handleChange}
              >
                <option value="KhachHang">Khách hàng</option>
                <option value="QuanTri">Quản trị</option>
              </select>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    </div>
  );
}
