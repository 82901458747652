import * as constants from "./constants";
const initialState = {
  isLoading: false,
  movieList: null,
  movieDetail: null,
};

const movieReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.MOVIE_LIST_REQUEST:
      state.isLoading = true;
      return { ...state };
    case constants.GET_MOVIE_LIST_SUCCESS:
      state.movieList = payload;
      state.isLoading = false;
      return { ...state };
    case constants.GET_MOVIE_DETAIL_SUCCESS:
      state.movieDetail = payload;
      state.isLoading = false;
      return { ...state };
    default:
      return { ...state };
  }
};
export default movieReducer;
