import React, { useState } from "react";
import logo from "../../../assets/img/logo/booking-movie-logo.png";
import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actLoginAPI } from "./module/actions";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAccount, setAccount] = useState({
    taiKhoan: "",
    matKhau: "",
  });
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAccount((isAccount) => ({
      ...isAccount,
      [name]: value,
    }));
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(actLoginAPI(isAccount, history));
  };

  return (
    <div id="login" className="login">
      <div className="login__wrapper">
        <div className="login__logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>

          <p>Mang cả thế giới phim đến với bạn</p>
        </div>

        <form className="login__main" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Tài khoản</label>
            <input
              type="text"
              className="form-control"
              name="taiKhoan"
              onChange={handleOnChange}
            />
          </div>
          <div className="form-group">
            <label>Mật khẩu</label>
            <input
              type="password"
              className="form-control"
              name="matKhau"
              onChange={handleOnChange}
            />
          </div>
          <button type="submit" className="btn">
            Đăng nhập
          </button>
        </form>
        <div className="login__redirect-register">
          <span>Bạn chưa có tài khoản?</span>
          <Link to={"/register"} className="signup">
            Đăng ký
          </Link>
        </div>
        <Link to={"/"} className="login__close">
          <i class="fa fa-times"></i>
        </Link>
      </div>
    </div>
  );
}
