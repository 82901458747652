import axios from "axios";

class CinemaService {
  getCinemaSystemInfomation = () => {
    return axios({
      method: "get",
      url:
        "https://movie0706.cybersoft.edu.vn/api/QuanLyRap/LayThongTinHeThongRap",
    });
  };
  fetchCinemaChildInformation = (id) => {
    return axios({
      method: "GET",
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyRap/LayThongTinCumRapTheoHeThong?maHeThongRap=${id}`,
    });
  };
  // fetchScheduleCinemaSystem = (id) => {
  //   return axios({
  //     method: "GET",
  //     url: `https://movie0706.cybersoft.edu.vn/api/QuanLyRap/LayThongTinLichChieuHeThongRap?maHeThongRap=${id}&maNhom=GP05`,
  //   });
  // };
  fetchScheduleCinemaSystem = (id) => {
    return axios({
      method: "GET",
      url: `https://movie0706.cybersoft.edu.vn/api/QuanLyRap/LayThongTinLichChieuHeThongRap?maNhom=GP05`,
    });
  };
}

export default CinemaService;
