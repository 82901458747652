import Swal from "sweetalert2";
import { authService } from "../../../../services";

export const loginUserAdminAPI = (data, history) => {
  return () => {
    authService
      .loginUserAdminAPI(data)
      .then((res) => {
 
        console.log(res);
        if (res.data.maLoaiNguoiDung === "QuanTri") {
          localStorage.setItem("User", JSON.stringify(res.data));
          history.replace("/admin");
        } else {
          return Promise.reject({
            response: { data: "Bạn không có quyền truy cập" },
          });
        }
      })

      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: `${err.response.data}`,
        });
      });
  };
};
