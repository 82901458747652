import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DetailInfomation from "../DetailInfomation";
import DetailShowtimes from "../DetailShowtimes";
import "./style.scss";

export default function DetailBottom(props) {
  return (
    <div className="detail__bottom">
      <Tabs
        className="controlled-tab"
        defaultActiveKey="showtimes"
        id="controlled-tab"
      >
        <Tab eventKey="showtimes" title="Lịch chiếu">
          <DetailShowtimes />
        </Tab>
        <Tab eventKey="info" title="Thông tin">
          <DetailInfomation />
        </Tab>
      </Tabs>
    </div>
  );
}
