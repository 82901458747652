import React from "react";
import "./style.scss";

export default function NewsBlock() {
  return (
    <section className="new" id="tinTuc">
      {/* Nav tabs */}
      <ul className="nav nav-tabs" id="navId">
        <li className="nav-item">
          <a href="#DA24h" className="nav-link active" data-toggle="tab">
            Điện Ảnh 24h
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="DA24h" role="tabpanel">
          <div className="container">
            <div className="row ">
              <div className="col-sm-6">
                <a href="https://tix.vn/goc-dien-anh/7965-an-dinh-chac-nich-ngay-khoi-chieu-16-04-ly-hai-tung-clip-lat-mat-48h-dam-chat">
                  <img
                    // src="../../assets/img/khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon-16111317082644 (1).jpg"
                    src="https://s3img.vcdn.vn/123phim/2021/03/an-dinh-chac-nich-ngay-khoi-chieu-16-04-ly-hai-tung-clip-lat-mat-48h-dam-chat-fast-furious-mien-song-nuoc-16170881088272.png"
                    alt
                  />
                </a>
                <a
                  className="title"
                  href="https://tix.vn/goc-dien-anh/7965-an-dinh-chac-nich-ngay-khoi-chieu-16-04-ly-hai-tung-clip-lat-mat-48h-dam-chat"
                >
                  <span>
                    Ấn định chắc nịch ngày khởi chiếu 16.04, Lý Hải tung clip
                    Lật Mặt: 48H đậm chất
                  </span>
                </a>
                <p>
                  Trước thềm khởi chiếu 16.04 này, Lý Hải bất ngờ tung clip rượt
                  đuổi gay cấn thót tim fans hâm mộ
                </p>
              </div>
              <div className="col-sm-6">
                <a href="https://tix.vn/goc-dien-anh/7964-mortal-kombat-cuoc-chien-sinh-tu-goi-ten-nhung-phim-dien-anh-noi-tieng-duoc-chuyen-the-tu-cac-tua-game-dinh-dam">
                  <img
                    src="https://s3img.vcdn.vn/123phim/2021/03/mortal-kombat-cuoc-chien-sinh-tu-goi-ten-nhung-phim-dien-anh-noi-tieng-duoc-chuyen-the-tu-cac-tua-game-dinh-dam-16170160290762.png"
                    alt
                  />
                </a>
                <a
                  className="title"
                  href="https://tix.vn/goc-dien-anh/7964-mortal-kombat-cuoc-chien-sinh-tu-goi-ten-nhung-phim-dien-anh-noi-tieng-duoc-chuyen-the-tu-cac-tua-game-dinh-dam"
                >
                  <span>
                    [MORTAL KOMBAT: CUỘC CHIẾN SINH TỬ] - GỌI TÊN NHỮNG PHIM
                    ĐIỆN ẢNH NỔI TIẾNG
                  </span>
                </a>
                <p>
                  Bên cạnh những kịch bản gốc mới mẻ và đầy bất ngờ, Hollywood
                  cũng không thiếu những tác phẩm đình đám được chuyển thể từ
                  tiểu thuyết, phim hoạt hình, hay thậm chí là cả trò chơi điện
                  tử.
                </p>
              </div>
              <div className="col-sm-4">
                <a href="https://tix.vn/goc-dien-anh/7963-promising-young-woman-bong-hong-nuoc-anh-carey-mulligan-va-man-tra-thu-dan-ong-de-doi">
                  <img
                    src="https://s3img.vcdn.vn/123phim/2021/03/promising-young-woman-bong-hong-nuoc-anh-carey-mulligan-va-man-tra-thu-dan-ong-de-doi-16166710855522.png"
                    alt
                  />
                </a>
                <a
                  className="title"
                  href="https://tix.vn/goc-dien-anh/7963-promising-young-woman-bong-hong-nuoc-anh-carey-mulligan-va-man-tra-thu-dan-ong-de-doi"
                >
                  <span>
                    Tiệc Trăng Máu chính thức cán mốc 100 tỷ chỉ sau 2 tuần công
                    chiếu
                  </span>
                </a>
                <p>
                  Sau 2 tuần ra mắt, Tiệc Trăng Máu chính thức gia nhập câu lạc
                  bộ phim điện ảnh đạt 100 tỷ đồng doanh thu phòng vé. Dàn ngôi
                  sao “bạc tỷ” của phim cũng lần đầu tiên hội tụ đầy đủ trong
                  một khung hình để ăn mừng thành tích ấn tượng của tác phẩm.
                </p>
              </div>
              <div className="col-sm-4">
                <a href="https://tix.vn/goc-dien-anh/7962-vua-dep-lai-vua-tai-nang-dan-sao-nam-cua-phim-ban-tay-diet-quy-dam-bao-don-tim-hoi-chi-em">
                  <img
                    src="https://s3img.vcdn.vn/123phim/2021/03/vua-dep-lai-vua-tai-nang-dan-sao-nam-cua-phim-ban-tay-diet-quy-dam-bao-don-tim-hoi-chi-em-16165783843676.png"
                    alt
                  />
                </a>
                <a
                  className="title"
                  href="https://tix.vn/goc-dien-anh/7962-vua-dep-lai-vua-tai-nang-dan-sao-nam-cua-phim-ban-tay-diet-quy-dam-bao-don-tim-hoi-chi-em"
                >
                  <span>
                    VỪA ĐẸP LẠI VỪA TÀI NĂNG, DÀN SAO NAM CỦA PHIM “BÀN TAY DIỆT
                    QUỶ”
                  </span>
                </a>
                <p>
                  Sau 7 năm kể từ New World – bộ phim đạt thành tích hơn 4.68
                  triệu vé, hai tên tuổi lão làng trong làng điện ảnh Hàn Quốc
                  mới tiếp tục tái hợp trong Truy Cùng Giết Tận – một bộ phim
                  hành động siê{" "}
                </p>
              </div>
              <div className="col-sm-4">
                <div className="new__item">
                  <div>
                    <a href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon">
                      <img
                        src="https://s3img.vcdn.vn/123phim/2021/01/khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon-16115477671555.jpg"
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      className="reditem"
                      href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon"
                    >
                      [ANTEBELLUM] - 4 lý do không thể bỏ lỡ siêu phẩm kinh dị
                    </a>
                  </div>
                </div>
                <div className="new__item">
                  <div>
                    <a href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon">
                      <img
                        src="https://s3img.vcdn.vn/123phim/2020/11/boc-tem-to-hop-giai-tri-moi-toanh-cua-gioi-ha-thanh-16056939435004.png"
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      className="reditem"
                      href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon"
                    >
                      Ác Quỷ Đối Đầu soán ngôi Peninsula, trở thành phim đứng
                    </a>
                  </div>
                </div>
                <div className="new__item">
                  <div>
                    <a href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon">
                      <img
                        src="https://s3img.vcdn.vn/123phim/2020/11/tiec-trang-mau-chinh-thuc-can-moc-100-ty-chi-sau-2-tuan-cong-chieu-16043751284117.png"
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      className="reditem"
                      href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon"
                    >
                      Dàn mỹ nhân trong thế giới điện ảnh của quái kiệt
                    </a>
                  </div>
                </div>
                <div className="new__item">
                  <div>
                    <a href="https://tix.vn/goc-dien-anh/7961-khai-truong-rap-xin-gia-ngon-chuan-xi-tai-sai-gon">
                      <img
                        src="https://s3img.vcdn.vn/123phim/2020/10/ngo-thanh-van-chinh-thuc-khoi-dong-cuoc-thi-thiet-ke-trang-phuc-cho-sieu-anh-hung-dau-tien-cua-viet-nam-vinaman-16041584850247.jpg"
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      className="reditem"
                      href="https://s3img.vcdn.vn/123phim/2020/10/ngo-thanh-van-chinh-thuc-khoi-dong-cuoc-thi-thiet-ke-trang-phuc-cho-sieu-anh-hung-dau-tien-cua-viet-nam-vinaman-16041584850247.jpg"
                    >
                      Truy Cùng Giết Tận - Cuộc tái ngộ của hai 'ông hoàng phòng
                      vé'{" "}
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="new__button">
                <button>Xem Thêm</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
