import { cinemaService } from "../../../../../services";
import * as constant from "./constant";
import { createAction } from "../../../../../helpers/index";

export const fetchCinemaSystemInformation = (callback) => {
  return (dispatch) => {
    dispatch(createAction(constant.GET_CINEMA_SYSTEM_INFOMATION_REQUEST, null));
    cinemaService
      .getCinemaSystemInfomation()
      .then((res) => {
        dispatch(
          createAction(constant.GET_CINEMA_SYSTEM_INFOMATION_SUCCESS, res.data)
        );
        callback();
      })
      .catch((err) => {
        dispatch(
          createAction(constant.GET_CINEMA_SYSTEM_INFOMATION_FAILED, err)
        );
      });
  };
};

export const fetchCinemaChildInformation = (id) => {
  return (dispatch) => {
    dispatch(createAction(constant.GET_CINEMA_CHILD_INFOMATION_REQUEST, null));
    cinemaService
      .fetchCinemaChildInformation(id)
      .then((res) => {
        dispatch(
          createAction(constant.GET_CINEMA_CHILD_INFOMATION_SUCCESS, res.data)
        );
      })
      .catch((err) => {
        dispatch(
          createAction(constant.GET_CINEMA_CHILD_INFOMATION_FAILED, err)
        );
      });
  };
};

export const fetchScheduleCinemaSystem = (id, callback) => {
  return (dispatch) => {
    dispatch(createAction(constant.GET_SCHEDULE_CINEMA_SYSTEM_REQUEST));
    cinemaService
      .fetchScheduleCinemaSystem(id)
      .then((res) => {
        dispatch(
          createAction(constant.GET_SCHEDULE_CINEMA_SYSTEM_SUCCESS, res.data)
        );
        callback();
      })
      .catch((err) => {
        dispatch(createAction(constant.GET_SCHEDULE_CINEMA_SYSTEM_FAILED, err));
      });
  };
};
